import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import backIcon from '../../assets/back.png'; // Adjust the path as necessary
import menuIcon from '../../assets/menu.png';
import openBot from '../../assets/open_bot.png';
import reloadPage from '../../assets/reload_page.png';
import termsOfUse from '../../assets/terms_of_use.png';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    if (dropdownVisible) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dropdownVisible, setDropdownVisible]);

  const goBack = () => {
    navigate(-1);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOpenBot = () => {
    window.location.href = 'https://t.me/dev_bot';
  }

  const handleReloadPage = () => {
    window.location.reload();
  };

  return (
    <div className="header">
      <div className="back-button" onClick={goBack}>
        <img src='/Images/Header/back-arrow.svg' alt="Back" />
      </div>
      <div className="title"><img src='/Images/Header/logo.svg' alt='/' /></div>
      <div className="menu" onClick={toggleDropdown}>
        <img src='/Images/Header/edit.svg' alt="Menu" />
      </div>
      {dropdownVisible && (
        <div ref={dropdownRef} className="dropdown-menu">
          <button onClick={handleClickOpenBot}>
            <img src={openBot} alt="Open Bot" />
            Open Bot
          </button>
          <button onClick={handleReloadPage}>
            <img src={reloadPage} alt="Reload Page" />
            Reload Page
          </button>
        </div>
      )}
    </div>
  );
};

export default Header;
