// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardscontainer_text-tic {
  font-size: 12px;
}

.raffelvard-image {
  width: 100%;
}
.raffel_main {
  width: 300px;
}
.cardscontainer {
  display: flex;
  align-items: center;
}
.raffelcard_d1 {
  display: flex;
  padding: 17.24px 12.07px 34.48px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #121212;
  border-left: 2px solid #121212;
  border-right: 2px solid #121212;
  border-radius: 0px 0px 17.24px 17.24px;
}
.raffelcard_d1_text1 {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: center;
  color: #ffffff;
  margin: 0px !important;
}
.raffel_main.selected{
  background: linear-gradient(#090909, #090909) padding-box,
    linear-gradient(92.23deg, #ff00d9 -19.69%, #6c4efe 100%) border-box;
    border: 2px solid transparent;
    border-radius: 18px;
}
@media (max-width: 375px) {
  .raffel_main {
    width: 250px;
  }
}
@media (max-width: 374px) {
  .cardscontainer_text-tic {
    font-size: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Raffel/raffel.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,gCAAgC;EAChC,mBAAmB;EACnB,8BAA8B;EAC9B,gCAAgC;EAChC,8BAA8B;EAC9B,+BAA+B;EAC/B,sCAAsC;AACxC;AACA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,cAAc;EACd,sBAAsB;AACxB;AACA;EACE;uEACqE;IACnE,6BAA6B;IAC7B,mBAAmB;AACvB;AACA;EACE;IACE,YAAY;EACd;AACF;AACA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".cardscontainer_text-tic {\n  font-size: 12px;\n}\n\n.raffelvard-image {\n  width: 100%;\n}\n.raffel_main {\n  width: 300px;\n}\n.cardscontainer {\n  display: flex;\n  align-items: center;\n}\n.raffelcard_d1 {\n  display: flex;\n  padding: 17.24px 12.07px 34.48px;\n  align-items: center;\n  justify-content: space-between;\n  border-bottom: 2px solid #121212;\n  border-left: 2px solid #121212;\n  border-right: 2px solid #121212;\n  border-radius: 0px 0px 17.24px 17.24px;\n}\n.raffelcard_d1_text1 {\n  font-family: Montserrat;\n  font-size: 12px;\n  font-weight: 500;\n  line-height: 14.63px;\n  text-align: center;\n  color: #ffffff;\n  margin: 0px !important;\n}\n.raffel_main.selected{\n  background: linear-gradient(#090909, #090909) padding-box,\n    linear-gradient(92.23deg, #ff00d9 -19.69%, #6c4efe 100%) border-box;\n    border: 2px solid transparent;\n    border-radius: 18px;\n}\n@media (max-width: 375px) {\n  .raffel_main {\n    width: 250px;\n  }\n}\n@media (max-width: 374px) {\n  .cardscontainer_text-tic {\n    font-size: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
