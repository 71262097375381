export const updateMissionStatus = async (token, missionId) => {
    try {
        const body = { missionId };
        console.log(token, body);
        const response = await fetch(`https://api.orbit-z.com/missionStatus`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        });
        if (response.ok) {
            return true;
        } else {
            console.log('Failed to update mission status');
            return false;
        }
    } catch (error) {
        console.error('Error in updateMissionStatus:', error);
        return false;
    }
}