export const redeemUser = async (
  userId,
  redeemPoints,
  redeemType,
  selectedChain,
  inputValue,
  againstPoint
) => {
  try {
    const response = await fetch(
      "https://api.orbit-z.com/mission/redeemUser",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, redeemPoints, redeemType,chain:selectedChain,walletAddress:inputValue,againstPoint }),
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to fetch user data");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
