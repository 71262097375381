import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import Communities from "./pages/Communities/Communities";
import { RedeemFirst } from "./pages/Communities/RedeemFirst/RedeemFirst";
import RedeemUSDT from "./pages/RedeemUSDT";
import Raffel from "./pages/Raffel/Raffel";

import Profile from "./pages/Profile/Profile";
import EditProfile from "./pages/Profile/EditProfile";
import Redeem from "./pages/Redeem";
import History from "./pages/History";

import HomePage from "./pages/HomePage";
import JoinClan from "./pages/JoinClan";
import Clan from "./pages/Clan";
import Invite from "./pages/Invite";
import Flip from "./pages/Flip";
import Earn from "./pages/Earn";
import Boosts from "./pages/Boosts";
import Presale from "./pages/Presale";
import Levels from "./pages/Levels";

const Main = () => {
  const location = useLocation(); // Get the current route
  const navigate = useNavigate(); // Use the navigate hook to programmatically navigate

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.disableVerticalSwipes) {
      window.Telegram.WebApp.disableVerticalSwipes();
      window.Telegram.WebApp.expand();

      const currentUrl = location.pathname; // Get current URL from location
      console.log("This is the current URL:", currentUrl);

      window.Telegram.WebApp.ready();

      if (currentUrl !== "/") {
        window.Telegram.WebApp.BackButton.show();

        const handleBackButtonClick = () => {
          if (window.history.length > 1) {
            window.history.back(); // Go back if history is available
          } else {
            navigate("/"); // Otherwise, navigate to the home page
          }
        };

        window.Telegram.WebApp.BackButton.onClick(handleBackButtonClick);

        return () => {
          window.Telegram.WebApp.BackButton.offClick(handleBackButtonClick);
          window.Telegram.WebApp.BackButton.hide();
        };
      } else {
        window.Telegram.WebApp.BackButton.hide();
      }
    } else {
      console.error("Telegram WebApp API is not available.");
    }
  }, [location.pathname, navigate]); // Re-run effect on route change

  return (
    <Routes>
      {/* <Route path="/" element={<HomePage />} /> */}
      <Route path="/joinclan" element={<JoinClan />} />
      <Route path="/joinClan/:id" element={<Clan />} />
      <Route path="/invite" element={<Invite />} />
      {/* <Route path="/flip" element={<Flip />} /> */}
      <Route path="/earn" element={<Earn />} />
      <Route path="/boosts" element={<Boosts />} />
      {/* <Route path='/presale' element={<Presale />} /> */}
      {/* <Route path='/levels' element={<Levels />} /> */}
      <Route path="/" element={<Profile />} />
      <Route path="/editProfile" element={<EditProfile />} />
      <Route path="/Redeem" element={<Redeem />} />
      <Route path="/RedeemUsdt" element={<RedeemUSDT />} />

      <Route path="/history" element={<History />} />
      <Route path="/community/:id" element={<Communities />} />
      <Route path="/redeemfirst" element={<RedeemFirst />} />
      <Route path="/raffel" element={<Raffel />} />
    </Routes>
  );
};

export default Main;
