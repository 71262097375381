// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div_main {
    padding: 25px 35px 54px 35px !important;
}

.div_main2 {
    padding: 20px 30px !important
}

.tasks-earn {

    font-size: 18px;

}

.earn-missiontitle {

    font-size: 12px;
    line-height: 16px;
    text-align: left;

}

@media screen and (max-width: 424px) {
    .tasks-earn {

        font-size: 15.7px;
        line-height: 20.93px;


    }

    .earn-missiontitle {

        font-size: 10.47px;
        /* line-height: 12.76px; */


    }

    .div_main {
        padding: 25px 15px 54px 15px !important;
    }

    .div_main2 {
        padding: 17.44px 26.16px !important
    }
    .div_main {
        padding: 25px 30.52px 54px 30.52px !important;
    }
}

@media screen and (max-width: 374px) {
    .tasks-earn {

        font-size: 13.4px;
        line-height: 17.86px;
    }
    .div_main2 {
        padding: 14.84px 22.33px !important
    }

    .earn-missiontitle {

        font-size: 8.93px;

        /* line-height: 10.89px; */

    }
    .div_main {
        padding: 25px 22.23px 54px 22.23px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Earn.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;AAC3C;;AAEA;IACI;AACJ;;AAEA;;IAEI,eAAe;;AAEnB;;AAEA;;IAEI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;;AAEpB;;AAEA;IACI;;QAEI,iBAAiB;QACjB,oBAAoB;;;IAGxB;;IAEA;;QAEI,kBAAkB;QAClB,0BAA0B;;;IAG9B;;IAEA;QACI,uCAAuC;IAC3C;;IAEA;QACI;IACJ;IACA;QACI,6CAA6C;IACjD;AACJ;;AAEA;IACI;;QAEI,iBAAiB;QACjB,oBAAoB;IACxB;IACA;QACI;IACJ;;IAEA;;QAEI,iBAAiB;;QAEjB,0BAA0B;;IAE9B;IACA;QACI,6CAA6C;IACjD;AACJ","sourcesContent":[".div_main {\n    padding: 25px 35px 54px 35px !important;\n}\n\n.div_main2 {\n    padding: 20px 30px !important\n}\n\n.tasks-earn {\n\n    font-size: 18px;\n\n}\n\n.earn-missiontitle {\n\n    font-size: 12px;\n    line-height: 16px;\n    text-align: left;\n\n}\n\n@media screen and (max-width: 424px) {\n    .tasks-earn {\n\n        font-size: 15.7px;\n        line-height: 20.93px;\n\n\n    }\n\n    .earn-missiontitle {\n\n        font-size: 10.47px;\n        /* line-height: 12.76px; */\n\n\n    }\n\n    .div_main {\n        padding: 25px 15px 54px 15px !important;\n    }\n\n    .div_main2 {\n        padding: 17.44px 26.16px !important\n    }\n    .div_main {\n        padding: 25px 30.52px 54px 30.52px !important;\n    }\n}\n\n@media screen and (max-width: 374px) {\n    .tasks-earn {\n\n        font-size: 13.4px;\n        line-height: 17.86px;\n    }\n    .div_main2 {\n        padding: 14.84px 22.33px !important\n    }\n\n    .earn-missiontitle {\n\n        font-size: 8.93px;\n\n        /* line-height: 10.89px; */\n\n    }\n    .div_main {\n        padding: 25px 22.23px 54px 22.23px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
