export const updateProfilePicture = async (token, userId,imageSrc) => {
  try {
    console.log("comming in side this",userId,imageSrc);
    
    const body = {};
    body.userId = userId;
    body.pictire=imageSrc;
    const response = await fetch("https://api.orbit-z.com/mission/updateUserPic", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error("Failed to update user data");
    } else return true;
  } catch (error) {
    console.error("Error:", error);
  }
};
