import { sendTelegramMessage } from "../api/sendTelegramMessage";

export const handleInvite = async (chatId, userName) => {
    const referralLink = `https://t.me/Orb1tz_bot?start=r_${chatId}`;
    const message = `Invite your friends and get bonuses for each invited friend!🎁\n\nYour referral link: \`${referralLink}\``;
    const forwardMessage = `🏆+1.5k Obitz as a first-time bonus\n💎+5k Obitz if you have Telegram Premium`;
    const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(forwardMessage)}`;
    const appUrl = `https://app.orbit-z.com/?userId=${chatId}&userName=${encodeURIComponent(userName)}`;
    // const appUrl = `https://api.orbit-z.com`;
    const inlineKeyboard = [
        [
            { text: "Invite a friend", url: telegramShareUrl }],
        [
            { text: "Back to Orbitz", web_app: { url: appUrl } }
        ],
    ];
    await sendTelegramMessage(chatId, message, inlineKeyboard);
}