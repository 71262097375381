import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Layout from "../components/Layout/Layout";

import { getClan } from "../api/getClan";
import { getClanUsersInfo } from "../api/getClanUsersInfo";
import { joinClan } from "../api/joinClan";
import { leaveClan } from "../api/leaveClan";

import { getMedalImage } from "../utils/getMedalImage";
import { handleInvite } from "../utils/handleInvite";
import { formatNumber } from "../utils/formatNumber";

import friendsIcon from "../assets/friends.png";

import "./HomePage.css";
import Header from "../components/Header/Header";

const Clan = () => {
  const { id } = useParams();
  const token = sessionStorage.getItem("token");
  const userClanId = sessionStorage.getItem("clanId");

  const navigate = useNavigate();
  const [clanData, setClanData] = useState({});
  const [memberData, setMemberData] = useState([]);
  const [clanExist, setClanExist] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getClanInfo = async () => {
      if (userClanId) {
        setClanExist(true);
      }
      const clanInfo = await getClan(id, token);
      const clanUsersInfo = await getClanUsersInfo(id, token);
      setClanData(clanInfo);
      setMemberData(clanUsersInfo);
      setIsLoading(false);
    };
    getClanInfo();
  }, []);

  const handleJoinClan = async () => {
    await joinClan(id, token);
    localStorage.setItem(
      "notification",
      `You have joined ${clanData.clanName} clan`
    );
    navigate("/");
  };

  const handleLeaveClan = async () => {
    await leaveClan(id, token);
    localStorage.setItem(
      "notification",
      `You have left ${clanData.clanName} clan`
    );
    navigate("/");
  };

  const handleInviteClick = async () => {
    const chatId = sessionStorage.getItem("chatId");
    const userName = sessionStorage.getItem("userName");
    await handleInvite(chatId, userName);
    if (
      window.Telegram &&
      window.Telegram.WebApp &&
      window.Telegram.WebApp.close
    ) {
      window.Telegram.WebApp.close();
    } else {
      console.error("Telegram WebApp API is not available.");
    }
  };

  return (
    <Layout>
      {!isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
         {/* <Header/> */}
        
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            paddingInline: "1rem",
          }}
        >
          <img
            src={clanData.profilePictureUrl}
            alt="Coin Icon"
            style={{ marginTop: "1.4375rem", width: "5rem", height: "5rem" }}
          />
          <span
            style={{
              marginTop: "2.375rem",
              color: "white",
              fontFamily: "Montserrat",
              fontSize: "1.75rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {clanData.clanName}
          </span>
          <span
            style={{
              marginTop: "1.25rem",
              color: "#F1F1F1",
              fontFamily: "Montserrat",
              fontSize: "1rem",
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            {clanData.description}
          </span>
          <div style={{ width: "100%" }}>
            <div
              style={{
                marginTop: "1.875rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "1.25rem",
                background: "rgba(140,140,140,0.1)",
                backdropFilter: "blur(0.5rem)",
                WebkitBackdropFilter: "blur(0.5rem)",
                padding: "1rem",
              }}
            >
              <button
                style={{
                  width: "100%",
                  background: "linear-gradient(45deg, #5DA8FF, #0064D7)",
                  paddingBlock: "1.125rem",
                  border: "none",
                  marginTop: "2.1875rem",
                  borderRadius: "18.75rem",
                  color: "white",
                  fontFamily: "Montserrat",
                  fontSize: "1rem",
                  cursor: "pointer",
                }}
                onClick={handleInviteClick}
              >
                Invite a Friend
              </button>
              {clanExist ? (
                <button
                  style={{
                    width: "100%",
                    background: "rgba(140,140,140,0.1)",
                    paddingBlock: "1.125rem",
                    border: "none",
                    marginTop: "2.1875rem",
                    borderRadius: "18.75rem",
                    color: "white",
                    fontFamily: "Montserrat",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={handleLeaveClan}
                >
                  Leave Clan
                </button>
              ) : (
                <button
                  style={{
                    width: "100%",
                    background: "rgba(140,140,140,0.1)",
                    paddingBlock: "1.125rem",
                    border: "none",
                    marginTop: "2.1875rem",
                    borderRadius: "18.75rem",
                    color: "white",
                    fontFamily: "Montserrat",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={handleJoinClan}
                >
                  Join Clan
                </button>
              )}
            </div>
            <div
              style={{
                marginTop: "1.875rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "1.25rem",
                background: "rgba(140,140,140,0.1)",
                backdropFilter: "blur(0.5rem)",
                WebkitBackdropFilter: "blur(0.5rem)",
                padding: "1rem",
              }}
            >
              {memberData.map((item) => (
                <div
                  key={item.userId}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                    cursor: "mouse",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "0.8125rem",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        borderRadius: "50%",
                        padding: "0.375rem 0.575rem",
                        background: "rgba(140,140,140,0.1)",
                        zIndex: 0,
                      }}
                    >
                      <img src={friendsIcon} alt="Friends" className="icon" />
                    </div>
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "1.5625rem",
                        height: "1.5625rem",
                        background: "linear-gradient(45deg, #5DA8FF, #0064D7)",
                        position: "relative",
                        top: "-1.5rem",
                        right: "-2.5875rem",
                        zIndex: 1,
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                          fontSize: "0.75rem",
                          color: "white",
                          verticalAlign: "middle",
                          display: "inline-block",
                        }}
                      >
                        {item.level}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "1rem",
                      gap: "0.3125rem",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        fontSize: "1.125rem",
                        color: "white",
                      }}
                    >
                      {item.userName}
                    </span>
                    <span
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "1.125rem",
                        color: "white",
                        opacity: "0.6",
                      }}
                    >
                      {formatNumber(item.score)}
                    </span>
                  </div>
                  {item.rank > 3 ? (
                    <span
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                        fontSize: "1rem",
                        color: "#0064D7",
                        background: "linear-gradient(45deg, #5DA8FF, #0064D7)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        marginLeft: "1.875rem",
                      }}
                    >
                      {item.rank}
                    </span>
                  ) : (
                    <img
                      src={getMedalImage(item.rank)}
                      alt="Gold Medal"
                      style={{
                        width: "1.875rem",
                        height: "1.875rem",
                        marginLeft: "1.875rem",
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        </>
      )}
    </Layout>
  );
};

export default Clan;
