import goldMedal from '../assets/goldMedal.png'
import silverMedal from '../assets/silverMedal.png'
import bronzeMedal from '../assets/bronzeMedal.png'

export const getMedalImage = (rank, src1, src2, src3) => {
    let src;
    switch (rank) {
        case 1: src = goldMedal; break;
        case 2: src = silverMedal; break;
        case 3: src = bronzeMedal; break;
        default: break;
    }
    return src;
}