import React, { useEffect, useState } from "react";

import Layout from "../components/Layout/Layout";
import NavBar from "../components/NavBar/NavBar";

import { getExchanges } from "../api/getExchanges";
import { updateExchangeStatus } from "../api/updateExchangeStatus";

import { formatNumber } from "../utils/formatNumber";

import coinIcon from "../assets/coin.png";
import copyIcon from "../assets/copyIcon.png";
import notificationIcon from "../assets/notificationIcon.png";

const Presale = () => {
  const token = sessionStorage.getItem("token");
  const exchangeId = sessionStorage.getItem("exchangeId");
  const [exchanges, setExchanges] = useState([]);
  const [exchangeStatus, setExchangeStatus] = useState("");
  const [totalVoteCount, setTotalVoteCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState("");
  const [notificationVisibile, setNotificationVisibile] = useState("false");
  const [accessCode, setAccessCode] = useState("4080437773");
  const [percent, setPercent] = useState(0);
  const [isAccessing, setIsAccessing] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(accessCode);
    setAccessCode("copied");
  };

  useEffect(() => {
    const initiateExchangeStatus = async () => {
      const exchangeData = await getExchanges(token);
      const voteCount = exchangeData.reduce(
        (total, exchange) => total + exchange.voteCount,
        0
      );
      setExchanges(exchangeData);
      setExchangeStatus(exchangeId);
      setTotalVoteCount(voteCount);

      setPercent((exchangeData[0].voteCount * 100) / voteCount);

      setIsLoading(false);
    };
    initiateExchangeStatus();
  }, []);

  const handleClickExchange = async (id) => {
    const apiResult = await updateExchangeStatus(token, id);
    if (!apiResult) {
      setNotification(`Vote incomplete`);
      setNotificationVisibile(true);

      setTimeout(() => {
        setNotificationVisibile(false);
        setTimeout(() => setNotification(""), 3000);
      }, 2000);
    } else {
      sessionStorage.setItem("exchangeId", id);
      setExchangeStatus(id);
      setNotification(`Successfully voted`);
      setNotificationVisibile(true);

      setTimeout(() => {
        setNotificationVisibile(false);
        setTimeout(() => setNotification(""), 3000);
      }, 2000);
    }
  };

  const handleClickAccessNow = () => {
    setIsAccessing(true);
  };

  const goBackToApp = () => {
    setIsAccessing(false);
  };
  return isAccessing ? (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <button
        style={{
          width: "100%",
          padding: "10px 0px",
          background: "linear-gradient(45deg, #5DA8FF, #0064D7)",
          cursor: "pointer",
          border: "none",
          fontFamily: "Montserrat",
          color: "white",
          fontSize: "1rem",
          fontWeight: "600",
        }}
        onClick={goBackToApp}
      >
        Back To App
      </button>
      <iframe
        style={{ height: "100%", width: "100%", border: "none" }}
        src="https://blue.presalepad.io"
        title="description"
        allowFullScreen
      ></iframe>
    </div>
  ) : (
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            paddingInline: "1.875rem",
          }}
        >
          <img
            src={coinIcon}
            alt="Coin Icon"
            style={{ marginTop: "1.4375rem", width: "5rem", height: "5rem" }}
          />
          <span
            style={{
              marginTop: "0.75rem",
              color: "white",
              fontFamily: "Montserrat",
              fontSize: "1.375rem",
              fontWeight: "600",
            }}
          >
            $BLUE Presale
          </span>
          <div
            style={{
              width: "100%",
              paddingInline: "0.875rem",
              display: "flex",
              gap: "0.6875rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  width: "100%",
                  borderRadius: "18.75rem",
                  background: "rgba(140,140,140,0.1)",
                  backdropFilter: "blur(0.5rem)",
                  WebkitBackdropFilter: "blur(0.5rem)",
                  marginBlock: "0.3125rem",
                  paddingBlock: "1rem",
                  cursor: "pointer",
                  border: "none",
                }}
                onClick={handleCopyClick}
              >
                <span
                  style={{
                    fontFamily: "Montserrat",
                    color: "white",
                    fontSize: "0.875rem",
                    fontWeight: "600",
                  }}
                >
                  {accessCode}
                </span>
                <img
                  src={copyIcon}
                  alt="Copy Icon"
                  style={{
                    width: "0.875rem",
                    height: "0.875rem",
                    marginLeft: "0.32rem",
                  }}
                ></img>
              </button>
              <span
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "0.625rem",
                  color: "#A3A3A3",
                }}
              >
                copy access code
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  width: "100%",
                  borderRadius: "18.75rem",
                  background: "linear-gradient(45deg, #5DA8FF, #0064D7)",
                  marginBlock: "0.3125rem",
                  paddingBlock: "0.89rem",
                  cursor: "pointer",
                  border: "none",
                  fontFamily: "Montserrat",
                  color: "white",
                  fontSize: "1rem",
                  fontWeight: "600",
                }}
                onClick={handleClickAccessNow}
              >
                Access Now
              </button>
              <span
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "0.625rem",
                  color: "#A3A3A3",
                }}
              ></span>
            </div>
          </div>
          <div
            style={{
              marginTop: "1.875rem",
              marginBottom: "0.8rem",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "1.25rem",
              background: "rgba(140,140,140,0.1)",
              backdropFilter: "blur(0.5rem)",
              WebkitBackdropFilter: "blur(0.5rem)",
              padding: "1.6875rem",
            }}
          >
            <span
              style={{
                width: "100%",
                color: "white",
                fontFamily: "Montserrat",
                fontSize: "1rem",
                fontWeight: "600",
                textAlign: "start",
              }}
            >
              Vote for your favourite exchange
            </span>
            <div
              style={{
                width: "100%",
                marginTop: "1.375rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              {exchanges.map((exchange) => (
                <div
                  key={exchange._id}
                  style={{
                    border: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "transparent",
                    width: "100%",
                  }}
                >
                  <img
                    src={exchange.photoUrl}
                    alt={exchange.title}
                    style={{
                      width: "2.5rem",
                      height: "2.5rem",
                      background: "rgba(140,140,140,0.1)",
                      borderRadius: "50%",
                      padding: "0.2rem",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      marginLeft: "1rem",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        fontSize: "0.75rem",
                        color: "white",
                        textAlign: "start",
                      }}
                    >
                      {exchange.title}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "0.4375rem",
                        width: "100%",
                        alignItems: "center",
                        gap: "0.25rem",
                      }}
                    >
                      <div style={{ position: "relative", width: "90%" }}>
                        <div
                          style={{
                            height: "0.161875rem",
                            backgroundColor: "rgba(124, 162, 220, 0.47)",
                            borderRadius: "6.25rem",
                            position: "absolute",
                            top: "25%",
                            width: "100%",
                            zIndex: "1",
                          }}
                        ></div>
                        <div
                          style={{
                            height: "0.25rem",
                            backgroundColor: "transparent",
                            bordeRadius: "0.625rem",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              width:
                                (exchange.voteCount * 100) / totalVoteCount ===
                                0
                                  ? "0%"
                                  : `${Math.round(
                                      (exchange.voteCount * 100) /
                                        totalVoteCount
                                    )}%`,
                              height: "100%",
                              background:
                                "linear-gradient(to right, #5DA8FF, #0064D7)",
                              borderRadius: "6.25rem",
                              transition: "width 0.1s linear",
                              position: "relative",
                              zIndex: "2",
                            }}
                          ></div>
                        </div>
                      </div>
                      <span
                        style={{
                          width: "10%",
                          fontFamily: "Montserrat",
                          fontSize: "0.625rem",
                          fontWeight: "400",
                          color: "white",
                        }}
                      >
                        {percent === 0
                          ? "0%"
                          : `${Math.round(
                              (exchange.voteCount * 100) / totalVoteCount
                            )}%`}
                      </span>
                    </div>
                  </div>
                  <button
                    style={{
                      borderRadius: "18.75rem",
                      cursor:
                        exchangeStatus !== "undefined" ? "auto" : "pointer",
                      background:
                        exchangeStatus === "undefined"
                          ? "linear-gradient(45deg, #5DA8FF, #0064D7)"
                          : exchangeStatus === exchange._id
                          ? "#1DAD00"
                          : "rgba(78,78,78,0.4)",
                      paddingBlock: "0.3125rem",
                      paddingInline: "1rem",
                      border: "none",
                      fontFamily: "Montserrat",
                      color: "white",
                      fontSize: "0.4375rem",
                      fontWeight: "600",
                      marginLeft: "1.5625rem",
                      opacity:
                        exchangeStatus !== "undefined" &&
                        exchangeStatus !== exchange._id
                          ? "0.4"
                          : "1",
                    }}
                    onClick={
                      exchangeStatus !== "undefined"
                        ? null
                        : () => handleClickExchange(exchange._id)
                    }
                  >
                    {exchangeStatus !== "undefined" &&
                    exchangeStatus === exchange._id
                      ? "Voted"
                      : "Vote"}
                  </button>
                </div>
              ))}
            </div>
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "0.75rem",
                fontWeight: "500",
                color: "#A3A3A3",
                marginTop: "1.2rem",
                textAlign: "right",
                width: "100%",
              }}
            >
              {formatNumber(totalVoteCount)} Votes
            </span>
          </div>
          {notification && (
            <div
              style={{
                position: "absolute",
                bottom: "1rem",
                zIndex: "2",
                width: "100%",
              }}
            >
              <div
                style={{
                  marginInline: "1.875rem",
                  background: "rgba(140, 140, 140, 0.1)",
                  backdropFilter: "blur(1rem)",
                  WebkitBackdropFilter: "blur(0.5rem)",
                  borderRadius: "18.75rem",
                  paddingBlock: "1.25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  opacity: notificationVisibile ? 1 : 0,
                  transition: "opacity 0.5s ease-out",
                  pointerEvents: notificationVisibile ? "auto" : "none",
                }}
              >
                <img
                  src={notificationIcon}
                  alt="Notification Icon"
                  style={{ width: "1.5625rem", height: "1.5625rem" }}
                />
                <span
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "1.5rem",
                    color: "white",
                    marginLeft: "0.375rem",
                  }}
                >
                  {notification}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      <div style={{ height: "65px" }}></div>
      <NavBar />
    </Layout>
  );
};

export default Presale;
