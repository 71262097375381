import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getProfile } from "../api/getProfile";
import { getClan } from "../api/getClan";
import { register } from "../api/register";
import { updateProfile } from "../api/updateProfile";
import { isEmpty } from "../utils/isEmpty";
import { formatUserCount } from "../utils/formatUserCount";
import { formatNumber } from "../utils/formatNumber";

import Layout from "../components/Layout/Layout";
import EnergyModal from "../components/EnergyModal/EnergyModal";
import NavBar from "../components/NavBar/NavBar";

import { GetLevel } from "./service";

import "./HomePage.css";
import "./button.css";

// Assets
import coinIcon from "../assets/coin.png";
import energyIcon from "../assets/energy.png";
import planetIcon from "../assets/planet.png";
import spaceship from "../assets/spaceship.png";
import notificationIcon from "../assets/notificationIcon.png";
import multiTapIcon from "../assets/multiTapIcon.png";
import lowBattery from "../assets/LowBattery.png";

// Constants
const initialCoinSize = 1;
const shrinkRate = 0.005;
const growIncrement = 0.017;
const coinScaleLevel1 = 1.19;
const coinScaleLevel2 = 1.44;
const coinScaleLevel3 = 1.71;
const maxCoinScale = 2;
const maxTime = 30;
const debounceDelay = 800;

const HomePage = () => {
  const navigate = useNavigate();

  const [points, setPoints] = useState(0);
  const [level, setLevel] = useState(0);
  const [coinScale, setCoinScale] = useState(initialCoinSize);
  const [isVibrating, setIsVibrating] = useState(false);
  const [energyCount, setEnergyCount] = useState(0);
  const [energyPercentage, setEnergyPercentage] = useState(100);
  const [showEnergyModal, setShowEnergyModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [remainingTime, setRemainingTime] = useState(null);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [flyingText, setFlyingText] = useState([]);
  const [flyingTextContent, setFlyingTextContent] = useState("");
  const [initialPointsIncrement, setInitialPointsIncrement] = useState(null);
  const [pointsIncrement, setPointsIncrement] = useState(1);
  const [notification, setNotification] = useState("");
  const [notificationVisibile, setNotificationVisibile] = useState(false);
  const [clan, setClan] = useState(null);
  const [clanInfo, setClanInfo] = useState({});

  const timerIntervalRef = useRef(null);
  const debounceTimeoutRef = useRef(null);

  const [remainingTimeForNextCharge, setRemainingTimeForNextCharge] = useState(
    30 * 60
  );
  const [maxEnergyCount, setMaxEnergyCount] = useState(0);
  const [isCharging, setIsCharging] = useState(false);
  let interval = "";

  useEffect(() => {
    const langPref = sessionStorage.getItem("Language");
    console.log(langPref, "this is language");
    if (!langPref) {
      // Tada
      sessionStorage.setItem("Language", "English");
    }
  }, []);

  useEffect(() => {
    const manageHomePage = async () => {
      const notificationData = localStorage.getItem("notification");
      if (notificationData) {
        setNotification(notificationData);
        setNotificationVisibile(true);
        localStorage.removeItem("notification");

        console.log("Notification set:", notificationData);

        setTimeout(() => {
          setNotificationVisibile(false);
          setTimeout(() => setNotification(""), 3000);
        }, 2000);
      }
      const token = sessionStorage.getItem("token");
      if (token) {
        const data = await getProfile(token);
        setPoints(data.user.score);
        setLevel(data.user.level);
        setEnergyCount(data.user.energyCount);
        setInitialPointsIncrement(data.user.multiTapLevel);
        setPointsIncrement(data.user.multiTapLevel);
        setFlyingTextContent(`+${data.user.multiTapLevel}`);
        setMaxEnergyCount(data.user.maxEnergyCount);
        if (data.user.isCharging) {
          setIsCharging(data.user.isCharging);
          setRemainingTimeForNextCharge(data.remainTime);
          // alert(data.remainTime);
        }
        if (data.user.clan) {
          // setClan('669fae1c36b3b2880ae91976');
          setClan(data.user.clan);
          const clanData = await getClan(data.user.clan, token);
          setClanInfo(clanData);
          if (clanData.tapBonus > 0) {
            setInitialPointsIncrement(data.user.multiTapLevel + 1);
            setPointsIncrement(data.user.multiTapLevel + 1);
            setFlyingTextContent(`+${data.user.multiTapLevel + 1}`);
          }
          sessionStorage.setItem("clanId", data.user.clan);
        } else {
          setClan(null);
          setClanInfo({});
          sessionStorage.removeItem("clanId");
        }
        setIsLoading(false);
        console.log(data.user.friends);
        sessionStorage.setItem("points", data.user.score);
        sessionStorage.setItem("level", data.user.level);
        sessionStorage.setItem(
          "normalReferralCount",
          data.user.normalReferralCount
        );
        sessionStorage.setItem(
          "premiumReferralCount",
          data.user.premiumReferralCount
        );
        sessionStorage.setItem("multiTapLevel", data.user.multiTapLevel);
        sessionStorage.setItem(
          "dailyAttemptsLevel",
          data.user.dailyAttemptsLevel
        );
        sessionStorage.setItem("exchangeId", data.user.exchange);
      } else {
        const userId =
          new URLSearchParams(window.location.search).get("userId") || "";
        const userName =
          new URLSearchParams(window.location.search).get("userName") || "";
        sessionStorage.setItem("userName", userName);

        if (userId) {
          const registerResponse = await register(userId, userName);
          setPoints(registerResponse.user.score);
          setLevel(registerResponse.user.level);
          setMaxEnergyCount(registerResponse.user.maxEnergyCount);
          setEnergyCount(registerResponse.user.energyCount);
          setInitialPointsIncrement(registerResponse.user.multiTapLevel);
          setPointsIncrement(registerResponse.user.multiTapLevel);
          setFlyingTextContent(`+${registerResponse.user.multiTapLevel}`);
          setIsCharging(registerResponse.user.isCharging);
          if (registerResponse.user.isCharging) {
            setRemainingTimeForNextCharge(registerResponse.remainTime);
            // alert(registerResponse.remainTime);
          }
          if (!isEmpty(registerResponse.user.clan)) {
            setClan(registerResponse.user.clan);
            const clanData = await getClan(
              registerResponse.user.clan,
              registerResponse.token
            );
            setClanInfo(clanData);
            if (clanData.tapBonus > 0) {
              setInitialPointsIncrement(
                registerResponse.user.multiTapLevel + 1
              );
              setPointsIncrement(registerResponse.user.multiTapLevel + 1);
              setFlyingTextContent(
                `+${registerResponse.user.multiTapLevel + 1}`
              );
            }
            sessionStorage.setItem("clanId", registerResponse.user.clan);
          } else {
            setClan(null);
            setClanInfo({});
            sessionStorage.removeItem("clanId");
          }
          setIsLoading(false);
          sessionStorage.setItem("token", registerResponse.token);
          sessionStorage.setItem("points", registerResponse.user.score);
          sessionStorage.setItem("level", registerResponse.user.level);
          sessionStorage.setItem("chatId", userId);
          sessionStorage.setItem(
            "normalReferralCount",
            registerResponse.user.normalReferralCount
          );
          sessionStorage.setItem(
            "premiumReferralCount",
            registerResponse.user.premiumReferralCount
          );
          sessionStorage.setItem(
            "multiTapLevel",
            registerResponse.user.multiTapLevel
          );
          sessionStorage.setItem(
            "dailyAttemptsLevel",
            registerResponse.user.dailyAttemptsLevel
          );
          sessionStorage.setItem("exchangeId", registerResponse.user.exchange);
        } else {
          if (
            window.Telegram &&
            window.Telegram.WebApp &&
            window.Telegram.WebApp.initData
          ) {
            const params = new URLSearchParams(window?.Telegram?.WebApp?.initData);
            const user = JSON.parse(decodeURIComponent(params?.get("user")));
            const registerResponse = await register(user?.id, user?.username);
            setPoints(registerResponse?.user?.score);
            setMaxEnergyCount(registerResponse?.user?.maxEnergyCount);
            setLevel(registerResponse?.user?.level);
            setEnergyCount(registerResponse?.user?.energyCount);
            setInitialPointsIncrement(registerResponse?.user?.multiTapLevel);
            setPointsIncrement(registerResponse?.user?.multiTapLevel);
            setFlyingTextContent(`+${registerResponse?.user?.multiTapLevel}`);
            setIsCharging(registerResponse.user.isCharging);
            if (registerResponse?.user?.isCharging) {
              setRemainingTimeForNextCharge(registerResponse?.remainTime);
              // alert(registerResponse.remainTime);
            }
            if (!isEmpty(registerResponse?.user?.clan)) {
              setClan(registerResponse.user.clan);
              const clanData = await getClan(
                registerResponse?.user?.clan,
                registerResponse?.token
              );
              setClanInfo(clanData);
              if (clanData?.tapBonus > 0) {
                setInitialPointsIncrement(
                  registerResponse?.user?.multiTapLevel + 1
                );
                setPointsIncrement(registerResponse?.user?.multiTapLevel + 1);
                setFlyingTextContent(
                  `+${registerResponse?.user?.multiTapLevel + 1}`
                );
              }
              sessionStorage.setItem("clanId", registerResponse?.user?.clan);
            } else {
              setClan(null);
              setClanInfo({});
              sessionStorage.removeItem("clanId");
            }
            setIsLoading(false);
            sessionStorage.setItem("token", registerResponse?.token);
            sessionStorage.setItem("points", registerResponse?.user?.score);
            sessionStorage.setItem("level", registerResponse?.user?.level);
            sessionStorage.setItem("chatId", user.id);
            sessionStorage.setItem(
              "normalReferralCount",
              registerResponse?.user?.normalReferralCount
            );
            sessionStorage.setItem(
              "premiumReferralCount",
              registerResponse?.user?.premiumReferralCount
            );
            sessionStorage.setItem(
              "multiTapLevel",
              registerResponse?.user?.multiTapLevel
            );
            sessionStorage.setItem(
              "dailyAttemptsLevel",
              registerResponse?.user?.dailyAttemptsLevel
            );
            sessionStorage.setItem(
              "exchangeId",
              registerResponse?.user?.exchange
            );
          } else {
            console.error("Telegram WebApp API is not available.");
          }
        }
      }
    };
    manageHomePage();
    // let inc = sessionStorage.getItem('multiTapLevel');
    // let total = sessionStorage.getItem('points');
    // if(points == NaN){
    //     setPointsIncrement(inc);
    //     setFlyingText(`+${inc}`);
    //     setPoints(total);
    // }
  }, []);

  useEffect(() => {
    if (remainingTime !== null) {
      setEnergyPercentage((remainingTime / maxTime) * 100);
    }
  }, [remainingTime]);

  useEffect(() => {
    const shrinkInterval = setInterval(() => {
      setCoinScale((prevScale) => {
        const newScale =
          prevScale > initialCoinSize
            ? prevScale - shrinkRate
            : initialCoinSize;
        setIsVibrating(newScale > initialCoinSize);
        return newScale;
      });
    }, 100);

    return () => clearInterval(shrinkInterval);
  }, []);

  useEffect(() => {
    if (energyCount === 0) {
      setShowEnergyModal(true);
    } else {
      setShowEnergyModal(false);
      setIsModalOpen(false);
    }
  }, [energyCount]);

  useEffect(() => {
    return () => {
      clearInterval(timerIntervalRef.current);
    };
  }, []);

  const handleClickJoinClan = () => {
    navigate("/joinclan");
  };
  const levels = () => {
    navigate("/levels");
  };
  const flip = () => {
    navigate("/flip");
  };
  const friends = () => {
    navigate("/invite");
  };
  const earn = () => {
    navigate("/earn");
  };
  const boosts = () => {
    navigate("/boosts");
  };

  const handleScreenClick = (event) => {
    if (
      isDebouncing ||
      event.target.closest("button") ||
      event.target.closest(".header") ||
      event.target.closest(".points") ||
      event.target.closest(".energy-container") ||
      event.target.closest(".clan")
    ) {
      return;
    }

    const boundingRect = event.currentTarget.getBoundingClientRect();

    if (energyCount === 0) {
      setIsModalOpen(true);
      return;
    }

    const newScale = coinScale + growIncrement;
    setIsVibrating(newScale > initialCoinSize);

    if (newScale >= maxCoinScale) {
      setPointsIncrement(initialPointsIncrement);
      setFlyingTextContent(`+${pointsIncrement}`);
      setRemainingTime(maxTime);
      setIsTimerActive(false);
      clearInterval(timerIntervalRef.current);
      setEnergyCount((prevEnergy) => {
        const newEnergyCount = prevEnergy - 1;
        const token = sessionStorage.getItem("token");
        return newEnergyCount;
      });
      setIsDebouncing(true);
      debounceTimeoutRef.current = setTimeout(() => {
        setIsDebouncing(false);
      }, debounceDelay);
      setCoinScale(initialCoinSize);
    } else {
      setCoinScale(newScale);
    }
    if (newScale > coinScaleLevel3) {
      setPointsIncrement(initialPointsIncrement * 4);
      setFlyingTextContent(`+${pointsIncrement}`);
    } else if (newScale > coinScaleLevel2) {
      setPointsIncrement(initialPointsIncrement * 3);
      setFlyingTextContent(`+${pointsIncrement}`);
    } else if (newScale > coinScaleLevel1) {
      setPointsIncrement(initialPointsIncrement * 2);
      setFlyingTextContent(`+${pointsIncrement}`);
    } else {
      setPointsIncrement(initialPointsIncrement);
      setFlyingTextContent(`+${pointsIncrement}`);
    }

    const randomAngle = 45 + Math.random() * 90;
    const radians = (randomAngle * Math.PI) / 180;
    const xOffset = Math.cos(radians) * 100;
    const yOffset = -Math.sin(radians) * 100;

    const newText = {
      id: Date.now(),
      x: event.clientX - boundingRect.left,
      y: event.clientY - boundingRect.top,
      xOffset: xOffset,
      yOffset: yOffset,
      rotationAngle: `${90 - randomAngle}deg`,
      content: flyingTextContent,
    };

    setFlyingText((prevTexts) => [...prevTexts, newText]);

    setTimeout(() => {
      setFlyingText((prevTexts) =>
        prevTexts.filter((text) => text.id !== newText.id)
      );
    }, 500);

    setPoints((prevScore) => prevScore + pointsIncrement);

    if (!isTimerActive) {
      const startPoints = points;
      setIsTimerActive(true);
      setRemainingTime(maxTime);

      timerIntervalRef.current = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1) {
            setIsDebouncing(true);
            debounceTimeoutRef.current = setTimeout(() => {
              setIsDebouncing(false);
            }, debounceDelay);
            clearInterval(timerIntervalRef.current);
            setIsTimerActive(false);
            setEnergyCount((prevEnergy) => {
              const newEnergyCount = prevEnergy - 1;
              const token = sessionStorage.getItem("token");

              return newEnergyCount;
            });
            setEnergyPercentage(100);
            setCoinScale(initialCoinSize);

            if (coinScale < maxCoinScale) {
              setPoints(startPoints);
            }

            return maxTime;
          } else {
            return prevTime - 1;
          }
        });
      }, 1000);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClickClan = (id) => {
    navigate(`/joinClan/${id}`);
  };

  // const handleClickPreSale = () => {
  //     navigate('/presale');
  // }

  return (
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="main-content" onMouseDown={handleScreenClick}>
          <div
            style={{
              width: "100%",
              paddingInline: "0.9375rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.6875rem",
            }}
          >
            {clan ? (
              <button
                disabled={isCharging}
                style={{
                  width: "50%",
                  borderRadius: "18.75rem",
                  background: "rgba(140,140,140,0.1)",
                  backdropFilter: "blur(0.5rem)",
                  WebkitBackdropFilter: "blur(0.5rem)",
                  marginBlock: "0.3125rem",
                  padding: "0.5rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  border: "none",
                  gap: "0.5rem",
                }}
                onClick={() => handleClickClan(clanInfo.id)}
                className="clan"
              >
                <img
                  src={clanInfo.profilePictureUrl}
                  alt="Avatar"
                  style={{
                    width: "2.5rem",
                    height: "2.5rem",
                    position: "relative",
                    borderRadius: "50%",
                  }}
                ></img>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.3125rem",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "0.75rem",
                      color: "white",
                      textAlign: "start",
                    }}
                  >
                    {clanInfo.clanName}
                  </span>
                  {clanInfo.energyBonus > 0 && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={energyIcon}
                        alt="Energy Icon"
                        style={{ width: "0.875rem", height: "0.875rem" }}
                      />
                      <span
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "0.5rem",
                          color: "white",
                          marginLeft: "0.25rem",
                        }}
                      >
                        +{clanInfo.energyBonus}
                      </span>
                    </div>
                  )}
                  {clanInfo.tapBonus > 0 && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={multiTapIcon}
                        alt="Multi Tap Icon"
                        style={{ width: "0.875rem", height: "0.875rem" }}
                      />
                      <span
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "0.5rem",
                          color: "white",
                          marginLeft: "0.25rem",
                        }}
                      >
                        +{clanInfo.tapBonus}
                      </span>
                    </div>
                  )}
                </div>
                <span
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "0.8125rem",
                    color: "white",
                    opacity: "0.6",
                  }}
                >
                  {formatUserCount(clanInfo.points)}
                  {clanInfo.points > 1000 && "+"}
                </span>
              </button>
            ) : (
              <button
                disabled={isCharging}
                style={{
                  width: "50%",
                  borderRadius: "18.75rem",
                  background: "#FFFFFF08",
                  WebkitBackdropFilter: "blur(0.5rem)",
                  marginBlock: "34px",
                  paddingBlock: "1rem",
                  cursor: "pointer",
                  border: "none",
                  fontFamily: "Montserrat",
                  color: "#FFFFFF",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
                onClick={handleClickJoinClan}
                className="HomePage__join-btn"
              >
                🪐 Join Clan
              </button>
            )}
            {/* <button style={{ width: '50%', borderRadius: '18.75rem', background: 'linear-gradient(45deg, #5DA8FF, #0064D7)', marginBlock: '0.3125rem', paddingBlock: '1rem', cursor: 'pointer', border: 'none', fontFamily: 'Montserrat', color: 'white', fontSize: '1.125rem', fontWeight: '600' }} onClick={handleClickPreSale} disabled={isCharging}>Presale</button> */}
          </div>
          <div className="points">{formatNumber(points)}</div>
          <button className="level" onClick={levels}>
            Level {GetLevel(points)}
          </button>
          <img src={planetIcon} alt="Planet" className="planet" />
          <img src={spaceship} alt="Spaceship" className="spaceship" />
          {/* <div className="coin-container"> */}
          <img
            src={coinIcon}
            alt="Coin"
            className={`coin ${isVibrating ? "vibrating" : ""}`}
            style={{ "--coin-scale": coinScale }}
          />
          {/* <div className="circle small"></div>
                        <div className="circle medium"></div>
                        <div className="circle large"></div>
                        <div className="circle xLarge"></div>
                    </div> */}
          <div className="energy-container">
            <img
              src={energyCount ? energyIcon : lowBattery}
              alt="Energy"
              className="energy-image"
            />
            <span className="energy-number">{energyCount}</span>
            <div className="energy-bar-wrapper">
              <div className="original-energy-bar"></div>
              <div className="energy-bar-container">
                <div
                  className="energy-bar"
                  style={{
                    width: `${energyPercentage}%`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: energyCount
                      ? " #FFFFFF1A"
                      : " linear-gradient(284.28deg, #654EA3 0%, #EAAFC8 90.64%)",
                  }}
                >
                  <div style={{ display: "flex", flexGrow: "1" }}></div>
                  {!!isCharging ? (
                    <span
                      style={{ color: "white", width: "30%", fontSize: "10px" }}
                    >
                      {Math.floor(remainingTimeForNextCharge / 60)}m{" "}
                      {remainingTimeForNextCharge % 60}s
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="remaining-time">
                  {remainingTime !== null
                    ? `${Math.floor(remainingTime / 60)}m ${
                        remainingTime % 60
                      }s`
                    : "0m 30s"}
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: "60px" }}></div>
          {isCharging ? <></> : <NavBar />}
          {flyingText.map((text) => (
            <div
              key={text.id}
              className="flying-text"
              style={{
                left: `${text.x}px`,
                top: `${text.y}px`,
                "--x-offset": `${text.xOffset}px`,
                "--y-offset": `${text.yOffset}px`,
                "--rotate-angle": text.rotationAngle,
              }}
            >
              {text.content}
            </div>
          ))}
          {notification && (
            <div
              style={{
                position: "absolute",
                bottom: "1rem",
                zIndex: "2",
                width: "100%",
              }}
            >
              <div
                style={{
                  marginInline: "1.875rem",
                  background: "rgba(140, 140, 140, 0.1)",
                  backdropFilter: "blur(1rem)",
                  WebkitBackdropFilter: "blur(0.5rem)",
                  borderRadius: "18.75rem",
                  paddingBlock: "1.25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  opacity: notificationVisibile ? 1 : 0,
                  transition: "opacity 0.5s ease-out",
                  pointerEvents: notificationVisibile ? "auto" : "none",
                }}
              >
                <img
                  src={notificationIcon}
                  alt="Notification Icon"
                  style={{ width: "1.5625rem", height: "1.5625rem" }}
                />
                <span
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "1.5rem",
                    color: "white",
                    marginLeft: "0.375rem",
                    textAlign: "center",
                  }}
                >
                  {notification}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      <EnergyModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </Layout>
  );
};

export default HomePage;
