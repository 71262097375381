// src/App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import { ToastContainer } from "react-toastify";
import Main from "./Main";

const App = () => {
  // useEffect(() => {
  //   if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.disableVerticalSwipes) {
  //     window.Telegram.WebApp.disableVerticalSwipes();
  //     window.Telegram.WebApp.expand();
  //   } else {
  //     console.error("Telegram WebApp API is not available.");
  //   }
  // });

  return (
    <>
      <ToastContainer
        position="center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // theme="light"
      />
      <Router>
        <Main />
      </Router>
    </>
  );
};

export default App;
