import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import Layout from "../components/Layout/Layout";
import NavBar from '../components/NavBar/NavBar';

import { getFlipStatus } from "../api/getFlipStatus";
import { updateFlipStatus } from "../api/updateFlipStatus";

import notificationIcon from '../assets/notificationIcon.png';
import successIcon from '../assets/successIcon.png';
import chillIcon from '../assets/chillIcon.png';
import workoutIcon from '../assets/energy_modal.png';

import { formatNumber } from "../utils/formatNumber";

const Flip = () => {
    const token = sessionStorage.getItem('token');
    const points = sessionStorage.getItem('points');
    const navigate = useNavigate();
    const [score, setScore] = useState(points);
    const [sliderValue, setSliderValue] = useState(100);
    const [mode, setMode] = useState(true);
    const [notification, setNotification] = useState('');
    const [notificationVisibile, setNotificationVisibile] = useState(false);
    const [notificationImage, setNotificationImage] = useState(notificationIcon);
    const [isLoading, setIsLoading] = useState(true);

    const handleSliderChange = (event) => {
        setSliderValue(event.target.value);
    };

    useEffect(() => {
        const setFlipStatus = async () => {
            const flipStatus = await getFlipStatus(token);
            setMode(flipStatus);
            setIsLoading(false);
        }
        setFlipStatus();
    }, []);

    const handleClickDouble = async () => {
        const flipBonus = Math.floor(sliderValue * Math.floor(score / 3) / 100);
        const flipSuccess = await updateFlipStatus(token, flipBonus);
        setMode(false);
        if (flipSuccess) {
            setNotification('You Won!');
            setNotificationVisibile(true);
            setNotificationImage(successIcon);
            setScore(+score + +flipBonus);
            setTimeout(() => {
                setNotificationVisibile(false);
                setTimeout(() => setNotification(''), 3000);
            }, 2000);
        } else {
            setNotification('You Lost!');
            setNotificationVisibile(true);
            setNotificationImage(notificationIcon);
            setTimeout(() => {
                setNotificationVisibile(false);
                setTimeout(() => setNotification(''), 3000);
            }, 2000);
        }
    }

    const handleClickContinue = () => {
        navigate('/');
    }

    return (
        <Layout>
            {isLoading ? (
                <div className="loading-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', paddingBlock: '1.5rem' }}>
                    <span style={{ marginTop: '0.3125rem', color: 'white', fontFamily: 'Montserrat', fontSize: '3.125rem', fontWeight: '700' }}>{formatNumber(score)}</span>
                    <div style={{ width: '100%', paddingInline: '1rem' }}>
                        {mode ? (
                            <div style={{ marginTop: '1.875rem', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '1.25rem', background: 'rgba(140,140,140,0.1)', backdropFilter: 'blur(0.5rem)', WebkitBackdropFilter: 'blur(0.5rem)', padding: '1.5625rem' }}>
                                <img src={chillIcon} alt="Chill Icon" style={{ width: '6.25rem', height: '6.25rem' }} />
                                <span style={{ marginTop: '1.5625rem', fontFamily: 'Montserrat', fontWeight: '600', fontSize: '1.5rem', color: 'white', textAlign: 'center' }}>Moon Coin Game x2</span>
                                <span style={{ marginTop: '1.5625rem', fontFamily: 'Montserrat', fontWeight: '400', fontSize: '1rem', color: '#F1F1F1', textAlign: 'center' }}>Try your Luck to Double your Coins and Level Up even Faster!</span>
                                <div>
                                    <span style={{ fontFamily: 'Montserrat', fontWeight: '400', fontSize: '1rem', color: '#F1F1F1' }}>Doubling Probability is </span>
                                    <span style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '1.25rem', color: '#0064D7', background: 'linear-gradient(45deg, #5DA8FF, #0064D7)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>95%</span>
                                </div>
                                <div style={{ marginTop: '2.1875rem', fontFamily: 'Montserrat', fontWeight: '700', fontSize: '1.75rem', color: 'white' }}>{formatNumber(Math.floor(sliderValue * Math.floor(score / 3) / 100))}</div>
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.9375rem', position: 'relative', width: '80%', maxWidth: '37.5rem', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <div style={{ height: '0.53125rem', backgroundColor: 'rgba(124, 162, 220, 0.47)', borderRadius: '6.25rem', position: 'absolute', top: '25%', width: '100%', zIndex: '1' }}></div>
                                        <div style={{ height: '0.8125rem', backgroundColor: 'transparent', borderRadius: '0.625rem', position: 'relative' }}>
                                            <div style={{ height: '100%', background: 'linear-gradient(to right, #5DA8FF, #0064D7)', borderRadius: '0.625rem', transition: 'width 0.001s linear', position: 'relative', zIndex: 2, width: `${sliderValue}%` }}></div>
                                            <div style={{ position: 'absolute', left: `${sliderValue}%`, top: '50%', transform: 'translate(-50%, -50%)', width: '1.25rem', height: '1.25rem', backgroundColor: '#fff', border: '0.125rem solid #0064D7', borderRadius: '50%', zIndex: 3, }} ></div>
                                        </div>
                                    </div>
                                    <input type="range" min="0" max="100" value={sliderValue} onChange={handleSliderChange} style={{ position: 'absolute', top: '50%', left: '0', width: '100%', height: '0.8125rem', opacity: '0', zIndex: '3', cursor: 'pointer' }} />
                                </div>
                                <button style={{ width: '100%', background: 'linear-gradient(45deg, #5DA8FF, #0064D7)', paddingBlock: '1rem', border: 'none', marginTop: '2.1875rem', borderRadius: '18.75rem', color: 'white', fontFamily: 'Montserrat', fontSize: '1.125rem', cursor: 'pointer', fontWeight: '600' }} onClick={handleClickDouble}>
                                    Double
                                </button>
                            </div>
                        ) : (
                            <div style={{ marginTop: '1.875rem', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '1.25rem', background: 'rgba(140,140,140,0.1)', backdropFilter: 'blur(0.5rem)', WebkitBackdropFilter: 'blur(0.5rem)', padding: '1.5625rem' }}>
                                <img src={workoutIcon} alt="Work Out Icon" style={{ width: '6.25rem', height: '6.25rem' }} />
                                <span style={{ marginTop: '1.5625rem', fontFamily: 'Montserrat', fontWeight: '600', fontSize: '1.5rem', color: 'white', textAlign: 'center' }}>Loading...</span>
                                <span style={{ marginTop: '1.5625rem', fontFamily: 'Montserrat', fontWeight: '400', fontSize: '1rem', color: '#F1F1F1', textAlign: 'center' }}>Come Back Tomorrow or Continue Tapping on the Dot</span>
                                <button style={{ width: '100%', background: 'linear-gradient(45deg, #5DA8FF, #0064D7)', paddingBlock: '1rem', border: 'none', marginTop: '2.1875rem', borderRadius: '18.75rem', color: 'white', fontFamily: 'Montserrat', fontSize: '1.125rem', cursor: 'pointer', fontWeight: '600' }} onClick={handleClickContinue}>
                                    Continue
                                </button>
                            </div>
                        )}

                    </div>
                    {notification &&
                        <div style={{ position: 'absolute', bottom: '1rem', zIndex: '2', width: '100%' }}>
                            <div style={{ marginInline: '1.875rem', background: 'rgba(140, 140, 140, 0.1)', backdropFilter: 'blur(1rem)', WebkitBackdropFilter: 'blur(0.5rem)', borderRadius: '18.75rem', paddingBlock: '1.25rem', display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: notificationVisibile ? 1 : 0, transition: 'opacity 0.5s ease-out', pointerEvents: notificationVisibile ? 'auto' : 'none' }}>
                                <img src={notificationImage} alt='Notification Icon' style={{ width: '1.5625rem', height: '1.5625rem' }} />
                                <span style={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '1.5rem', color: 'white', marginLeft: '0.375rem' }}>{notification}</span>
                            </div>
                        </div>
                    }
                </div>
            )}
            <div style={{height: '65px'}}></div>
            <NavBar />
        </ Layout >
    );
};

export default Flip;