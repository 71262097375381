import React, { useEffect, useState } from "react";
import "./comunities.css";
import NavBar from "../../components/NavBar/NavBar";
import { useNavigate, useParams } from "react-router-dom";
import { getClan } from "../../api/getClan";
import { getClanUsersInfo } from "../../api/getClanUsersInfo";
import { joinClan } from "../../api/joinClan";
import { leaveClan } from "../../api/leaveClan";
import Header from "../../components/Header/Header";
import Layout from "../../components/Layout/Layout";

const Communities = () => {
  const { id } = useParams();

  const [clanData, setClanData] = useState({});
  const [memberData, setMemberData] = useState([]);
  const [clanExist, setClanExist] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userToken, setUserToken] = useState();
  const navigate = useNavigate();
  console.log("this is member data", memberData);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    setUserToken(token);
    const userClanId = sessionStorage.getItem("clanId");
    const getClanInfo = async () => {
      if (userClanId) {
        setClanExist(true);
      }

      const clanInfo = await getClan(id, token);
      const clanUsersInfo = await getClanUsersInfo(id, token);
      setClanData(clanInfo);
      setMemberData(clanUsersInfo);
      setIsLoading(false);
    };
    getClanInfo();
  }, []);

  console.log(clanData);

  const handleJoinClan = async () => {
    setIsLoading(true);
    await joinClan(id, userToken);
    setIsLoading(false);
    localStorage.setItem(
      "notification",
      `You have joined ${clanData.clanName} clan`
    );
    navigate("/joinclan");
  };
  const handleLeaveClan = async () => {
    setIsLoading(true);
    await leaveClan(id, userToken);
    setIsLoading(false);
    localStorage.setItem(
      "notification",
      `You have left ${clanData.clanName} clan`
    );
    navigate("/joinclan");
  };

  return (
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          {/* <Header/> */}
          <div className="back-icon">
            <img
              src="/Images/back-arrow.svg"
              alt="back"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="bg-img">
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 46.52%)",
                paddingTop: "300px",
                paddingBottom: "150px",
                // height: "98vh",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  //   paddingInline: "1rem",
                  // height: "500px",
                  alignItems: "end",
                  justifyContent: "center",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                }}
              >
                <div className="community-textdiv">
                  <h1 className="community__text-gradient">Orbitz</h1>
                  <h1 className="community__text">Orbitz</h1>
                </div>
              </div>

              <div
                style={{ paddingTop: "41px", paddingBottom: "0px" }}
                className="middle-invite"
              >
                {clanExist ? (
                  <button
                    style={{
                      width: "100%",
                      height: "60px",
                      background:
                        "linear-gradient(92.23deg, #FF00D9 -19.69%, #6C4EFE 100%)",
                      borderRadius: "130px",
                      color: "#FFFFFF",
                      fontFamily: "Montserrat",
                      fontSize: "18px",
                      fontWeight: "600",
                      lineHeight: "24px",
                      textAlign: "center",
                      marginBottom: "15px",
                      // marginTop: "9px",
                      border: "none",
                    }}
                    className="community__btn"
                    onClick={handleLeaveClan}
                  >
                    Leave Clan
                  </button>
                ) : (
                  <button
                    style={{
                      width: "100%",
                      height: "60px",
                      background:
                        "linear-gradient(92.23deg, #FF00D9 -19.69%, #6C4EFE 100%)",
                      borderRadius: "130px",
                      color: "#FFFFFF",
                      fontFamily: "Montserrat",
                      fontSize: "18px",
                      fontWeight: "600",
                      lineHeight: "24px",
                      border: "none",
                      // marginTop: "9px",
                      marginBottom: "15px",
                      textAlign: "center",
                    }}
                    className="community__btn"
                    onClick={handleJoinClan}
                  >
                    Join Clan
                  </button>
                )}
                <div style={{}} className="clans__data">
                  {memberData?.length > 0 ? (
                    memberData?.map((item) => (
                      <div
                        key={item?._id}
                        // onClick={() => handleClick(item?._id)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          background: "#0E0E0E",
                          borderRadius: "15px",
                          padding: "12px 15px",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            // marginTop: "0.8125rem",
                          }}
                        >
                          <img
                            src="/Images/be.svg"
                            alt="Avatar"
                            style={{
                              width: "30px",
                              height: "30px",
                              position: "relative",
                              borderRadius: "50%",
                              zIndex: 0,
                            }}
                          />
                          {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "9.375rem",
                      background: "linear-gradient(45deg, #5DA8FF, #0064D7)",
                      paddingBlock: "0.3125rem",
                      paddingInline: "0.46875rem",
                      position: "relative",
                      top: "-0.8125rem",
                      zIndex: 1,
                    }}
                  >
                    <img
                      src={userIcon}
                      alt="User Icon"
                      style={{ width: "0.625rem", height: "0.625rem" }}
                    />
                    <span
                      style={{
                        color: "white",
                        fontFamily: "Montserrat",
                        fontSize: "0.5rem",
                        fontWeight: "500",
                        marginLeft: "0.4375rem",
                      }}
                    >
                      {formatUserCount(item.userCount)}
                          {item.userCount > 1000 && "+"}
                    </span>
                  </div> */}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "10px",
                            gap: "0.3125rem",
                            width: "100%",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "MontserratBold",
                              fontWeight: "800",
                              fontSize: "16px",
                              color: "#F7F9FB",
                              fontStyle: "italic",
                              lineHeight: "24px",
                            }}
                            className="joinClan__main-ClanName"
                          >
                            {item?.userName}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="bottomtext">No clans available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <NavBar />
    </Layout>
  );
};

export default Communities;
