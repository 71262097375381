// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  user-select: none;
  /* Disable text selection */
  -webkit-user-select: none;
  /* For Webkit browsers */
  -moz-user-select: none;
  /* For Firefox */
  -ms-user-select: none;
  /* For IE/Edge */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  /* Setting base font size */
}

body,
html,
#root,
.App {
  height: 100%;
  max-width: 31.25rem;
  /* 500px */
  margin: auto;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  position: relative;
}

.earnmoreponts {
  font-size: 56px;
  line-height: 68.26px;
}

@media (max-width: 390px) {
  .earnmoreponts {
    font-size: 48px;
    line-height: 58.26px;
  }
}

@media (max-width: 330px) {
  .earnmoreponts {
    font-size: 41.67px;

    line-height: 50.8px;
    letter-spacing: -2.232558250427246px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,2BAA2B;EAC3B,yBAAyB;EACzB,wBAAwB;EACxB,sBAAsB;EACtB,gBAAgB;EAChB,qBAAqB;EACrB,gBAAgB;EAChB,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,2BAA2B;AAC7B;;AAEA;;;;EAIE,YAAY;EACZ,mBAAmB;EACnB,UAAU;EACV,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE;IACE,eAAe;IACf,oBAAoB;EACtB;AACF;;AAEA;EACE;IACE,kBAAkB;;IAElB,mBAAmB;IACnB,oCAAoC;EACtC;AACF","sourcesContent":["* {\n  user-select: none;\n  /* Disable text selection */\n  -webkit-user-select: none;\n  /* For Webkit browsers */\n  -moz-user-select: none;\n  /* For Firefox */\n  -ms-user-select: none;\n  /* For IE/Edge */\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nhtml {\n  font-size: 16px;\n  /* Setting base font size */\n}\n\nbody,\nhtml,\n#root,\n.App {\n  height: 100%;\n  max-width: 31.25rem;\n  /* 500px */\n  margin: auto;\n}\n\n.App {\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: #f5f5f5;\n  position: relative;\n}\n\n.earnmoreponts {\n  font-size: 56px;\n  line-height: 68.26px;\n}\n\n@media (max-width: 390px) {\n  .earnmoreponts {\n    font-size: 48px;\n    line-height: 58.26px;\n  }\n}\n\n@media (max-width: 330px) {\n  .earnmoreponts {\n    font-size: 41.67px;\n\n    line-height: 50.8px;\n    letter-spacing: -2.232558250427246px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
