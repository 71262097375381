import React, { useEffect, useState, useRef } from "react";
import Layout from "../../components/Layout/Layout";
import NavBar from "../../components/NavBar/NavBar";
import "./profile.css";
import { useDispatch } from "react-redux";
import { createUser, getUserData } from "../../service/redux/middleware/user";
import axios from "axios";
import { SuccessToast } from "../../components/Toast/Toast";
import { API_URL, API_URL_Admin } from "../../service/client";
import CircularProgress from "@mui/material/CircularProgress";
import ScreenLoader from "../../components/loader/ScreenLoader";
import { getProfile } from "../../api/getProfile";
import { updateProfile } from "../../api/updateProfile";
import Header from "../../components/Header/Header";
import astronaut from "../../assets/astronaut.png";
import closeIcon from "../../assets/closeIcon.png";
import { useNavigate } from "react-router-dom";
import { updateProfilePicture } from "../../api/updateProfilePic";
import { sendTelegramMessage } from "../../api/sendTelegramMessage";

const EditProfile = () => {
  // States for handleing countries downdrop search fiels
  const [country, setCountry] = useState("");
  const [flag, setFlag] = useState("");
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  //////////////////////////

  const defaultprofile = "/Images/Profile/be-profile.png";
  const [imageSrc, setImageSrc] = useState(defaultprofile);
  const [telegramUsername, setTelegramUsername] = useState("");
  const [name, setName] = useState("");
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [beUserId, setBeUserId] = useState("");
  const [profileImageUpdate, setProfileImageUpdate] = useState(false);
  const [profileData, setProfileData] = useState();
  const [profileImage, setProfileImage] = useState();
  const dispatch = useDispatch();
  const fileref = useRef();
  const [screenLoader, setScreenLoader] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [wrongId, setWrongId] = useState(false);
  const [nameCheck, setNameCheck] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [userIds, setUserIds] = useState();
  // const [isEnglish, setIsEnglish] = useState(false);
  const [lang, setLang] = useState("");
  const dropdownRef = useRef(null);
  const chatId = sessionStorage.getItem("chatId");
  const navigate = useNavigate();

  // async function setUpUser() {
  //   try {
  //     if (!name) {
  //       // ErrorToast("Pleas Enter your Name")
  //       return;
  //     }
  //     setUpdateLoader(true);

  //     // setLoader(true)

  //     const data = {
  //       name: name,
  //       userName: telegramUsername,
  //       beId: beUserId,
  //       imagesSrc: imageSrc,
  //     };
  //     dispatch(createUser(data)).then((res) => {
  //       console.log("thjis is the uiser response", res);
  //       if (res.payload.status === 200) {
  //         SuccessToast("Profile Updated SuccessFully");

  //         setUpdateLoader(false);
  //       } else {
  //         setUpdateLoader(false);
  //       }
  //       // setLoader(false)
  //       // navigate("/joinclan")
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  // https://storage.cloud.google.com/be-app-ee2c9.appspot.com//${mission.photoUrl}?authuser=0

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setProfileImageUpdate(true);

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);

      try {
        const token = sessionStorage.getItem("token");
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios.post(`${API_URL_Admin}/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("This is response", response);

        // if (!response.ok) {
        //   throw new Error("Failed to upload profile picture");
        // }

        // const resData = await response.json();
        setProfileImageUpdate(false);

        // console.log("Profile res", resData?.data);
        setImageSrc(
          `https://storage.cloud.google.com/be-app-ee2c9.appspot.com//${response?.data?.fileName}?authuser=0`
        );
        const data = await updateProfilePicture(
          token,
          userIds,
          `https://storage.cloud.google.com/be-app-ee2c9.appspot.com//${response?.data?.fileName}?authuser=0`
        );
        console.log(data);
      } catch (error) {
        setProfileImageUpdate(false);
        console.error("Error:", error);
      }
    }
  };

  useEffect(() => {
    const savedLanguage = sessionStorage.getItem("Language");
    setLang(savedLanguage);

    setIsloading(true);
    const token = sessionStorage.getItem("token");
    async function getData() {
      if (token) {
        const data = await getProfile(token);
        setProfileData(data?.user);

        setName(data?.user?.name);
        if (data?.user?.beUserId == 0) {
          setBeUserId("");
        } else {
          setBeUserId(data?.user?.beUserId);
        }
        setUserIds(data?.user?._id);

        setTelegramUsername(data?.user?.userName);
        setImageSrc(data?.user?.profilePic);

        setCountry(data?.user?.country);
        setFlag(await getCountryFlag(data?.user?.country));

        // setbeId(data?.user?.beUserId);
        // setTotalPoints(data?.user?.score);
        // setUserId(data?.user?._id);
        setIsloading(false);
      }
    }
    getData();
  }, []);

  const validateCustomer = async (customerId) => {
    const url = `https://api.fastshoppingstore.com/points-exchange/validate-customer?customerId=${customerId}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "X-API-Key":
            "MJelS1yGLXabynwZg2u9XVmCGQcuFn42GM9KrvZ4hYeaAPnHpEWHE8fXGl7lg4QS",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Response Data:", data);
      return data;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  async function setUpUser() {
    try {
      const token = sessionStorage.getItem("token");
      if (!name) {
        setNameCheck(true);
        return;
      }
      setIsloading(true);
      // Usage
      let datas;
      if (beUserId !== "") {
        datas = await validateCustomer(beUserId);
      }

      const data = await updateProfile(
        token,
        name,
        datas?.data?.valid ? beUserId : "",
        imageSrc,
        country
      );
      if (!datas?.data?.valid && beUserId !== "") {
        setDropdownVisible(true);
      }
      console.log("this is updated data", datas);
      // navigate("/joinclan");
      if (data) {
        setIsloading(false);
      } else {
        setIsloading(false);
      }
    } catch (error) {
      console.log(error);
      setIsloading(false);
    }
  }
  useEffect(() => {
    if (dropdownVisible) {
      // Disable background scrolling by setting body overflow to hidden
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable scrolling when the dropdown is not visible
      document.body.style.overflow = "auto";
    }

    // Cleanup function to restore scrolling when the component unmounts or dropdownVisible changes
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [dropdownVisible]);

  const handleImageSelect = async (e) => {
    setIsloading(true);
    const message = "Please send me the command: /uploadPicture";
    await sendTelegramMessage(chatId, message);
    setIsloading(false);
    if (
      window.Telegram &&
      window.Telegram.WebApp &&
      window.Telegram.WebApp.close
    ) {
      window.Telegram.WebApp.close();
    } else {
      console.error("Telegram WebApp API is not available.");
    }
  };

  console.log("userData", profileData);

  // Handlelling Countrries searching and inputs etc
  useEffect(() => {
    // Fetch countries with names and flags from an API
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countryData = response.data.map((country) => ({
          name: country.name.common,
          flag: country.flags.png, // URL for the flag image
        }));
        setCountries(countryData);
      })
      .catch((error) => console.error("Error fetching country data:", error));
  }, []);

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    setCountry(userInput);
    setFlag("");

    if (userInput) {
      const filtered = countries.filter((c) =>
        c.name.toLowerCase().startsWith(userInput.toLowerCase())
      );
      setFilteredCountries(filtered);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleCountrySelect = (selectedCountry) => {
    sessionStorage.setItem("userCountry", selectedCountry.name);
    setCountry(selectedCountry.name);
    setFlag(selectedCountry.flag);
    setShowDropdown(false);
  };

  async function getCountryFlag(countryName) {
    try {
      // Fetch all countries data from the API
      const response = await fetch("https://restcountries.com/v3.1/all");

      if (!response.ok) {
        throw new Error("Failed to fetch country data.");
      }

      const countries = await response.json();

      // Find the country by name
      const country = countries.find(
        (c) => c.name.common.toLowerCase() === countryName.toLowerCase()
      );

      if (!country) {
        throw new Error("Country not found.");
      }

      // Return the country flag
      return country.flags.svg;
    } catch (error) {
      console.error(error.message);
      return null; // Return null or an appropriate value if not found
    }
  }

  return (
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          {/* <Header /> */}
          <div>
            <div style={{ marginTop: "20px" }}>
              {/* <img
                src="/Images/back-arrow.svg"
                alt="back"
                onClick={() => {
                  navigate(-1);
                }}
              /> */}
            </div>
            <div className="bg-img">
              <div className="pfp__bg">
                {/* <img
                  style={{
                    maxWidth: "190px",
                    maxHeight: "190px",
                    borderRadius: "200px",
                    width: "145px",
                    height: "145px",
                  }}
                  src={imageSrc}
                  alt=""
                  className="pfp__bg"
                /> */}
              </div>
              <div
                style={{ paddingLeft: "35px", paddingRight: "35px" }}
                className="editProfile__btn"
              >
                {/* <div onClick={()=>{handleImageSelect()}} className="edit__btn" style={{ cursor: "pointer" }}>
                  <label htmlFor="upload">
                    <p>
                      {isEnglish
                        ? "Edit Profile Image"
                        : "Modifica Immagine del Profilo"}
                    </p>
                  </label>
                  <input
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    type="file"
                    className="hidden"
                    id="upload"
                    ref={fileref}
                    accept="image/*"
                  />
                </div> */}
              </div>
              <div className="profile__input" style={{ paddingTop: "240px" }}>
                <div className="profile__input-txt1">
                  <label>
                    {lang === "English"
                      ? "Telegram Username"
                      : lang === "Italian"
                      ? "Nome utente Telegram"
                      : "Nombre de usuario en Telegram"}
                  </label>
                  <input
                    value={telegramUsername}
                    placeholder="Lasper"
                    readOnly
                  />
                  {/* <img src="/Images/Profile/edit.svg" alt="" className="editProfile__icon" /> */}
                </div>
                <div className="profile__input-txt1">
                  <label>
                    {lang === "English"
                      ? "Name"
                      : lang === "Italian"
                      ? "Nome"
                      : "Nombre"}
                  </label>
                  <input
                    value={name}
                    placeholder="Your Name"
                    onChange={(e) => {
                      setNameCheck(false);
                      setName(e.target.value);
                    }}
                  />
                </div>
                {nameCheck && (
                  <p style={{ color: "red" }}>
                    {lang === "English"
                      ? "Name cannnot be empty"
                      : lang === "Italian"
                      ? "Il nome non può essere vuoto."
                      : "El nombre no puede estar vacío"}
                  </p>
                )}
                {/* Country Div is here */}
                <div className="profile__input-txt">
                  <label>
                    {lang === "English"
                      ? "Country"
                      : lang === "Italian"
                      ? "paese"
                      : "país"}
                    {/* <span style={{ color: "#C80C00" }}>*</span> */}
                  </label>
                  <div className="Profile_Country_Search_div">
                    <input
                      placeholder={
                        lang === "English"
                          ? "Search Country"
                          : lang === "Italian"
                          ? "Inserisci il nome"
                          : "Introduzca el nombre"
                      }
                      value={country}
                      onChange={handleInputChange}
                    />
                    {flag && (
                      <img
                        src={flag}
                        alt={`${country} flag`}
                        style={{ width: "24px", marginRight: "10px" }}
                      />
                    )}
                    <img src="/Images/Profile/Country_search.svg" alt="" />
                  </div>
                  {showDropdown && (
                    <ul className="dropdown">
                      {filteredCountries.map((c, index) => (
                        <li
                          key={index}
                          onClick={() => handleCountrySelect(c)}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            src={c.flag}
                            alt={`${c.name} flag`}
                            style={{ width: "20px", marginRight: "10px" }}
                          />
                          {c.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                {/* =========== Country ============ */}
                <div className="profile__input-txt1">
                  <label>
                    {lang === "English"
                      ? "Customer ID"
                      : lang === "Italian"
                      ? "ID Cliente"
                      : "ID de cliente"}
                  </label>
                  <input
                    value={beUserId}
                    // placeholder="lasper1234"
                    onChange={(e) => {
                      setWrongId(false);
                      setBeUserId(e.target.value);
                    }}
                  />
                  {wrongId && (
                    <p style={{ color: "red" }}>
                      {lang === "English"
                        ? "Your Id is wrong"
                        : lang === "Italian"
                        ? "Il tuo ID è errato"
                        : "Tu identificación es incorrecta"}
                    </p>
                  )}
                </div>
                <div className="profile__btn">
                  <button
                    disabled={updateLoader ? true : false}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={setUpUser}
                  >
                    <p>
                      {lang === "English"
                        ? "Set Up"
                        : lang === "Italian"
                        ? "Imposta"
                        : "Configuración"}
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <NavBar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {dropdownVisible && (
          <>
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.1)", // Black background with 10% opacity
                backdropFilter: "blur(5px)", // Backdrop blur effect
                WebkitBackdropFilter: "blur(5px)", // Backdrop blur effect for Safari
                zIndex: 998, // Behind the dropdown
              }}
            ></div>
            <div
              ref={dropdownRef}
              style={{
                width: "90%",
                borderRadius: "1.25rem 1.25rem 1.25rem 1.25rem",
                background: "#8C8C8C1A",
                backdropFilter: "blur(3rem)",
                WebkitBackdropFilter: "blur(3rem)",
                position: "fixed",
                bottom: "25%",
                display: "flex",
                flexDirection: "column",
                padding: "32px 35px",
                alignItems: "center",
                // gap: "25px",
                zIndex: "999",
              }}
              className="redeemModal"
            >
              <button
                style={{
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                  float: "right",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={closeDropdown}
              >
                <img
                  src={closeIcon}
                  alt="Close Icon"
                  style={{ width: "1.875rem", height: "1.875rem" }}
                />
              </button>
              <img
                src={astronaut}
                alt="Astronaut"
                style={{ width: "100px", height: "100px" }}
              />
              <span
                style={{
                  // marginTop: "0.5625rem",
                  fontFamily: "MontserratBold",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "transparent",
                  // lineHeight: "24px",
                  fontStyle: "italic",
                }}
                className="profile__modal-h"
              >
                {lang === "English"
                  ? "Customer ID is Incorrect!"
                  : lang === "Italian"
                  ? "L'ID cliente è errato!"
                  : "span"}
              </span>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    // marginTop: "0.5625rem",
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "#F1F1F1",
                    textAlign: "center",
                    lineHeight: "24px",
                    paddingTop: "5px",
                    paddingBottom: "25px",
                  }}
                  className="profile__modal-p"
                >
                  {lang === "English"
                    ? "Please enter correct Customer ID"
                    : lang === "Italian"
                    ? "Si prega di inserire l'ID cliente corretto"
                    : "span"}
                </span>
              </div>

              <div style={{ width: "100%" }}>
                <button
                  onClick={() => {
                    setDropdownVisible(false);
                  }}
                  className="profile__modal-btn"
                >
                  {lang === "English"
                    ? "Continue"
                    : lang === "Italian"
                    ? "Continua"
                    : "span"}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default EditProfile;
