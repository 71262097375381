// Stars.js
import React, { useEffect, useRef } from 'react';
import './Stars.css';

const Stars = ({ appRef }) => {
    const starsContainerRef = useRef(null);

    useEffect(() => {
        const generateStars = () => {
            if (!appRef.current) return;

            const numberOfStars = 100;
            const starsContainer = starsContainerRef.current;
            const appElement = appRef.current;
            const appHeight = appElement.clientHeight;
            const appWidth = appElement.clientWidth;

            for (let i = 0; i < numberOfStars; i++) {
                const star = document.createElement('div');
                const xPos = Math.random() * appWidth;
                const yPos = Math.random() * appHeight;
                const size = Math.random() * 2 + 1;
                const twinklingDuration = (Math.random() * 3 + 2).toFixed(2);
                const delay = (Math.random() * 5).toFixed(2);

                star.classList.add('star');
                star.style.left = `${xPos}px`;
                star.style.top = `${yPos}px`;
                star.style.width = `${size}px`;
                star.style.height = `${size}px`;
                star.style.animationDelay = `${delay}s, ${delay}s`;
                star.style.animationDuration = `${twinklingDuration}s, ${Math.random() * 20 + 10}s`;

                starsContainer.appendChild(star);

                setInterval(() => {
                    star.style.left = `${Math.random() * appWidth}px`;
                    star.style.top = `${Math.random() * appHeight}px`;
                }, twinklingDuration * 1000 * 5);
            }
        };

        generateStars();
    }, [appRef]);

    return <div className="stars-container" ref={starsContainerRef}></div>;
};

export default Stars;
