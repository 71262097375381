import React from "react";
import Lottie from "lottie-react";
import animationData from "./animation.json";

function ScreenLoader({ text }) {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        zIndex: 999999999999,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.8)",
        position: "absolute",
        // bottom:"50%"
        
      }}
    >
      <div
        style={{ color: "black",position:"absolute",bottom:"30%" }}
        className="d-flex flex-column align-items-center"
      >
        <Lottie
          animationData={animationData}
          loop={true}
          autoplay={true}
        //   style={{ width: 700, height: 700 }}
        />
      </div>
    </div>
  );
}

export default ScreenLoader;