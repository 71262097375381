import React from 'react';
import energyModalIcon from '../../assets/energy_modal.png';
import energyIcon from '../../assets/energy.png';
import coinIcon from '../../assets/coin.png';
import './EnergyModal.css';

const EnergyModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose()}>
            <div className="modal">
                <div className="modal-header">
                    <img src={energyModalIcon} alt="Energy Modal Icon" className="modal-icon" />
                </div>
                <div className="modal-title">
                    <h2>Attempts Restoring</h2>
                </div>
                <div className="modal-body">
                    <p className="modal-message">Meanwhile you can watch ads to restore attempts or earn tokens by completing tasks</p>
                </div>
                <div className="modal-footer">
                    <button className="modal-button">
                        <img src={energyIcon} alt="Energy Icon" className="button-icon" />
                        Speed Up
                    </button>
                    <button className="modal-button">
                        <img src={coinIcon} alt="Coin Icon" className="button-icon" />
                        Earn Coins
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EnergyModal;
