// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* button:hover{
  background : linear-gradient(45deg, #5DA8FF, #437bbc)
}

button:active{
  background : linear-gradient(45deg, #5DA8FF, #104787)
} */`, "",{"version":3,"sources":["webpack://./src/pages/button.css"],"names":[],"mappings":"AAAA;;;;;;GAMG","sourcesContent":["/* button:hover{\n  background : linear-gradient(45deg, #5DA8FF, #437bbc)\n}\n\nbutton:active{\n  background : linear-gradient(45deg, #5DA8FF, #104787)\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
