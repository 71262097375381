// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-container {
    position: relative;
    overflow: hidden;
    background-color: #000000;
    min-height: 100%;
}

.gradient_1,
.gradient_2 {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
}

.gradient_1 {
    top: 0;
    left: 0;
    width: 22.1875rem;
    /* 355px */
    height: 22.1875rem;
    /* 355px */
    opacity: 0.1;
    -webkit-backdrop-filter: blur(0.625rem);
            backdrop-filter: blur(0.625rem);
    /* 10px */
}

.gradient_2 {
    bottom: 0;
    right: 0;
    width: 22.1875rem;
    /* 355px */
    height: 22.1875rem;
    /* 355px */
    opacity: 0.1;
    -webkit-backdrop-filter: blur(0.625rem);
            backdrop-filter: blur(0.625rem);
    /* 10px */
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;;IAEI,kBAAkB;IAClB,4BAA4B;IAC5B,wBAAwB;IACxB,WAAW;AACf;;AAEA;IACI,MAAM;IACN,OAAO;IACP,iBAAiB;IACjB,UAAU;IACV,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,uCAA+B;YAA/B,+BAA+B;IAC/B,SAAS;AACb;;AAEA;IACI,SAAS;IACT,QAAQ;IACR,iBAAiB;IACjB,UAAU;IACV,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,uCAA+B;YAA/B,+BAA+B;IAC/B,SAAS;AACb","sourcesContent":[".top-container {\n    position: relative;\n    overflow: hidden;\n    background-color: #000000;\n    min-height: 100%;\n}\n\n.gradient_1,\n.gradient_2 {\n    position: absolute;\n    background-repeat: no-repeat;\n    background-size: contain;\n    z-index: -1;\n}\n\n.gradient_1 {\n    top: 0;\n    left: 0;\n    width: 22.1875rem;\n    /* 355px */\n    height: 22.1875rem;\n    /* 355px */\n    opacity: 0.1;\n    backdrop-filter: blur(0.625rem);\n    /* 10px */\n}\n\n.gradient_2 {\n    bottom: 0;\n    right: 0;\n    width: 22.1875rem;\n    /* 355px */\n    height: 22.1875rem;\n    /* 355px */\n    opacity: 0.1;\n    backdrop-filter: blur(0.625rem);\n    /* 10px */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
