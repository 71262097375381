import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const getListNft = createAsyncThunk("getListNft", async (data) => {
  try {
    const res = await api.get(`${API_URL}/api/getListNft/${data}`);
    return {
      status: res?.status,
      data: res?.data,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

export const getUserData=createAsyncThunk("getUserData",async(data)=>{
    try {
        const res = await api.get(`${API_URL}/auth/getUser?userName=${data}`);
        return {
          status: res?.status,
          data: res?.data,
        };
      } catch (error) {
        return {
          message: error?.response?.data?.error,
          status: error?.response?.status,
        };
      }
})
export const createUser=createAsyncThunk("createUser",async(data)=>{
    try {
        const res = await api.post(`${API_URL}/auth/createUser`,data);
        return {
          status: res?.status,
          data: res?.data,
        };
      } catch (error) {
        return {
          message: error?.response?.data?.error,
          status: error?.response?.status,
        };
      }
})

export const addUser=createAsyncThunk("addUser",async(data)=>{
    try {
        const res = await api.post(`${API_URL}/auth/addUser`,data);
        return {
          status: res?.status,
          data: res?.data,
        };
      } catch (error) {
        return {
          message: error?.response?.data?.error,
          status: error?.response?.status,
        };
      }
})

