import React, { useEffect, useRef, useState } from "react";
import "./profile.css";
import Layout from "../../components/Layout/Layout";
import NavBar from "../../components/NavBar/NavBar";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

import { addUser, createUser, getUserData } from "../../service/redux/middleware/user";
import { ErrorToast, SuccessToast } from "../../components/Toast/Toast";
import ScreenLoader from "../../components/loader/ScreenLoader";
import CircularProgress from "@mui/material/CircularProgress";

import { getProfile } from "../../api/getProfile";
import { getClan } from "../../api/getClan";
import { register } from "../../api/register";
import { updateProfile } from "../../api/updateProfile";
import { isEmpty } from "../../utils/isEmpty";
import { formatUserCount } from "../../utils/formatUserCount";
import { formatNumber } from "../../utils/formatNumber";

const initialCoinSize = 1;
const shrinkRate = 0.005;
const growIncrement = 0.017;
const coinScaleLevel1 = 1.19;
const coinScaleLevel2 = 1.44;
const coinScaleLevel3 = 1.71;
const maxCoinScale = 2;
const maxTime = 30;
const debounceDelay = 800;

const countries = [
  "Argentina",
  "Australia",
  "Belgium",
  "Brazil",
  "Canada",
  "China",
  "Denmark",
  "Egypt",
  "France",
  "Germany",
  "India",
  "Italy",
  "Japan",
  "Mexico",
  "Namibia",
  "Spain",
  "United States",
  "Pakistan✅",
];

const Profile = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract query parameters from the URL
  const queryParams = new URLSearchParams(location.search);
  const initialTelegramUsername = queryParams.get("telegramUsername") || "";
  console.log();

  // State variables for input fields
  const [telegramUsername, setTelegramUsername] = useState(initialTelegramUsername);
  const [name, setName] = useState("");
  // States for handleing countries downdrop search fiels
  const [country, setCountry] = useState("");
  const [flag, setFlag] = useState("");
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  //////////////////////////
  const [beUserId, setBeUserId] = useState("");
  const [updateLoader, setUpdateLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isUserAdded, setIsUserAdded] = useState(false); // Add a flag state to prevent multiple addUser calls

  const [points, setPoints] = useState(0);
  const [level, setLevel] = useState(0);
  const [coinScale, setCoinScale] = useState(initialCoinSize);
  const [isVibrating, setIsVibrating] = useState(false);
  const [energyCount, setEnergyCount] = useState(0);
  const [energyPercentage, setEnergyPercentage] = useState(100);
  const [showEnergyModal, setShowEnergyModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [remainingTime, setRemainingTime] = useState(null);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [flyingText, setFlyingText] = useState([]);
  const [flyingTextContent, setFlyingTextContent] = useState("");
  const [initialPointsIncrement, setInitialPointsIncrement] = useState(null);
  const [pointsIncrement, setPointsIncrement] = useState(1);
  const [notification, setNotification] = useState("");
  const [notificationVisibile, setNotificationVisibile] = useState(false);
  const [clan, setClan] = useState(null);
  const [clanInfo, setClanInfo] = useState({});
  const [userName, setUserName] = useState();

  const timerIntervalRef = useRef(null);
  const debounceTimeoutRef = useRef(null);

  const [remainingTimeForNextCharge, setRemainingTimeForNextCharge] = useState(30 * 60);
  const [maxEnergyCount, setMaxEnergyCount] = useState(0);
  const [isCharging, setIsCharging] = useState(false);
  let interval = "";

  const dispatch = useDispatch();
  console.log(telegramUsername);

  async function setUpUser() {
    try {
      const token = sessionStorage.getItem("token");
      if (!name) {
        ErrorToast("Pleas Enter your Name");
        return;
      }
      setIsLoading(true);
      // Usage
      const datas = await validateCustomer(beUserId);
      const imgSrc = "";
      const data = await updateProfile(token, name, datas?.data?.valid ? beUserId : "", imgSrc, country);

      console.log("this is updated data", datas);
      navigate("/joinclan");
      if (data) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  const stopCharge = (energy) => {
    const token = sessionStorage.getItem("token");
    updateProfile(token, points, energy, false);
    setIsCharging(false);
    clearInterval(interval);
    interval = "";
  };

  // const [isEnglish, setIsEnglish] = useState(false);
  const [lang, setLang] = useState("");

  useEffect(() => {
    const savedLanguage = sessionStorage.getItem("Language");

    if (savedLanguage === null) {
      setLang("English");
      sessionStorage.setItem("Language", "English");
    } else {
      setLang(savedLanguage);
    }

    const manageHomePage = async () => {
      const notificationData = localStorage.getItem("notification");
      const userId = new URLSearchParams(window.location.search).get("userId") || "";
      const userName = new URLSearchParams(window.location.search).get("userName") || "";
      setUserName(userName);
      if (notificationData) {
        setNotification(notificationData);
        setNotificationVisibile(true);
        localStorage.removeItem("notification");

        console.log("Notification set:", notificationData);

        setTimeout(() => {
          setNotificationVisibile(false);
          setTimeout(() => setNotification(""), 3000);
        }, 2000);
      }
      const token = sessionStorage.getItem("token");
      if (token) {
        const data = await getProfile(token);
        setPoints(data.user.score);
        setLevel(data.user.level);
        setEnergyCount(data.user.energyCount);
        setInitialPointsIncrement(data.user.multiTapLevel);
        setPointsIncrement(data.user.multiTapLevel);
        setFlyingTextContent(`+${data.user.multiTapLevel}`);
        setMaxEnergyCount(data.user.maxEnergyCount);
        setIsLoading(false);
        if (data.user.isCharging) {
          setIsCharging(data.user.isCharging);
          setRemainingTimeForNextCharge(data.remainTime);
          // alert(data.remainTime);
        }
       
        console.log(data.user.friends);
        sessionStorage.setItem("points", data.user.score);
        sessionStorage.setItem("level", data.user.level);
        sessionStorage.setItem("normalReferralCount", data.user.normalReferralCount);
        sessionStorage.setItem("premiumReferralCount", data.user.premiumReferralCount);


        if (data?.user?.isUpdated) {
          navigate("/joinclan");
        }
      } else {
        const userId = new URLSearchParams(window.location.search).get("userId") || "";
        const userName = new URLSearchParams(window.location.search).get("userName") || "";
        sessionStorage.setItem("userName", userName);

        if (userId) {
          const registerResponse = await register(userId, userName);
          console.log("this is register response",registerResponse)

          setPoints(registerResponse.user.score);
          setLevel(registerResponse.user.level);
          setMaxEnergyCount(registerResponse.user.maxEnergyCount);
          setEnergyCount(registerResponse.user.energyCount);
          setInitialPointsIncrement(registerResponse.user.multiTapLevel);
          setPointsIncrement(registerResponse.user.multiTapLevel);
          setFlyingTextContent(`+${registerResponse.user.multiTapLevel}`);
          setIsCharging(registerResponse.user.isCharging);
          
          
          setIsLoading(false);
          sessionStorage.setItem("token", registerResponse.token);
          sessionStorage.setItem("points", registerResponse.user.score);
          sessionStorage.setItem("level", registerResponse.user.level);
          sessionStorage.setItem("chatId", userId);
          sessionStorage.setItem("normalReferralCount", registerResponse.user.normalReferralCount);
          sessionStorage.setItem("premiumReferralCount", registerResponse.user.premiumReferralCount);
          sessionStorage.setItem("multiTapLevel", registerResponse.user.multiTapLevel);
          sessionStorage.setItem("dailyAttemptsLevel", registerResponse.user.dailyAttemptsLevel);
          
          if (registerResponse?.user?.isUpdated) {
            navigate("/joinclan");
          }
        } else {
          if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
            const params = new URLSearchParams(window.Telegram.WebApp.initData);
            const user = JSON.parse(decodeURIComponent(params.get("user")));
            const registerResponse = await register(user.id, user.username);
            setPoints(registerResponse.user.score);
            setMaxEnergyCount(registerResponse.user.maxEnergyCount);
            setLevel(registerResponse.user.level);
            setEnergyCount(registerResponse.user.energyCount);
            setInitialPointsIncrement(registerResponse.user.multiTapLevel);
            setPointsIncrement(registerResponse.user.multiTapLevel);
            setFlyingTextContent(`+${registerResponse.user.multiTapLevel}`);
            setIsCharging(registerResponse.user.isCharging);
            if (registerResponse.user.isCharging) {
              setRemainingTimeForNextCharge(registerResponse.remainTime);
              // alert(registerResponse.remainTime);
            }
           
            setIsLoading(false);
            sessionStorage.setItem("token", registerResponse.token);
            sessionStorage.setItem("points", registerResponse.user.score);
            sessionStorage.setItem("level", registerResponse.user.level);
            sessionStorage.setItem("chatId", user.id);
            sessionStorage.setItem("normalReferralCount", registerResponse.user.normalReferralCount);
            sessionStorage.setItem("premiumReferralCount", registerResponse.user.premiumReferralCount);
            sessionStorage.setItem("multiTapLevel", registerResponse.user.multiTapLevel);

            if (registerResponse?.user?.isUpdated) {
              navigate("/joinclan");
            }
          } else {
            console.error("Telegram WebApp API is not available.");
          }
        }
      }
    };
    manageHomePage();
    // let inc = sessionStorage.getItem('multiTapLevel');
    // let total = sessionStorage.getItem('points');
    // if(points == NaN){
    //     setPointsIncrement(inc);
    //     setFlyingText(`+${inc}`);
    //     setPoints(total);
    // }
  }, []);

  const validateCustomer = async (customerId) => {
    const url = `https://api.fastshoppingstore.com/points-exchange/validate-customer?customerId=${customerId}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "X-API-Key": "MJelS1yGLXabynwZg2u9XVmCGQcuFn42GM9KrvZ4hYeaAPnHpEWHE8fXGl7lg4QS",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Response Data:", data);
      return data;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Handlelling Countrries searching and inputs etc
  useEffect(() => {
    // Fetch countries with names and flags from an API
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countryData = response.data.map((country) => ({
          name: country.name.common,
          flag: country.flags.png, // URL for the flag image
        }));
        setCountries(countryData);
      })
      .catch((error) => console.error("Error fetching country data:", error));
  }, []);

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    setCountry(userInput);
    setFlag("");

    if (userInput) {
      const filtered = countries.filter((c) => c.name.toLowerCase().startsWith(userInput.toLowerCase()));
      setFilteredCountries(filtered);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleCountrySelect = (selectedCountry) => {
    sessionStorage.setItem("userCountry", selectedCountry.name);
    setCountry(selectedCountry.name);
    setFlag(selectedCountry.flag);
    setShowDropdown(false);
  };

  return (
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div style={{ marginTop: "67px" }}>
          <div className="bg-img">
            <div
              style={{
                background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 46.52%)",
                paddingTop: "160px",
              }}
            >
              <div className="profile__main">
                <h1 className="profile__main-head">
                  {" "}
                  {lang === "English" ? "Set Up Your Profile" : lang === "Italian" ? "Imposta il tuo profilo" : "Configura tu perfil"}
                </h1>
                <h1 className="profile__main-head-gradient">
                  {lang === "English" ? "Set Up Your Profile" : lang === "Italian" ? "Imposta il tuo profilo" : "Configura tu perfil"}
                </h1>
              </div>
              <div className="profile__input">
                <div className="profile__input-txt">
                  <label>
                    {lang === "English" ? "Telegram Username" : lang === "Italian" ? "Nome utente Telegram" : "Nombre de usuario de Telegrama"}
                  </label>
                  <input
                    placeholder="@BEUser"
                    value={userName}
                    readOnly
                    // onChange={(e) => setTelegramUsername(e.target.value)}
                  />
                </div>
                <div className="profile__input-txt">
                  <label>
                    {lang === "English" ? "Name" : lang === "Italian" ? "Nome" : "Nombre"} <span style={{ color: "#C80C00" }}>*</span>
                  </label>
                  <input
                    placeholder={lang === "English" ? "Enter Name" : lang === "Italian" ? "Inserisci il nome" : "Introduzca el nombre"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                {/* Country Div is here */}
                <div className="profile__input-txt">
                  <label>
                    {lang === "English" ? "Country" : lang === "Italian" ? "Nome" : "Nombre"} <span style={{ color: "#C80C00" }}>*</span>
                  </label>
                  <div className="Profile_Country_Search_div">
                    <input
                      placeholder={lang === "English" ? "Search Country" : lang === "Italian" ? "Inserisci il nome" : "Introduzca el nombre"}
                      value={country}
                      onChange={handleInputChange}
                    />
                    {flag && <img src={flag} alt={`${country} flag`} style={{ width: "20px", marginRight: "10px" }} />}
                    <img src="/Images/Profile/Country_search.svg" alt="" />
                  </div>
                  {showDropdown && (
                    <ul className="dropdown">
                      {filteredCountries.map((c, index) => (
                        <li key={index} onClick={() => handleCountrySelect(c)} style={{ display: "flex", alignItems: "center" }}>
                          <img src={c.flag} alt={`${c.name} flag`} style={{ width: "20px", marginRight: "10px" }} />
                          {c.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                {/* =========== Country ============ */}
                <div className="profile__input-txt">
                  <label>
                    {lang === "English" ? "Customer ID" : lang === "Italian" ? "ID Cliente" : "ID de cliente"}
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "12px",
                        fontWeight: "500",
                        opacity: "30%",
                        background: "transparent",
                        backgroundClip: "unset",
                      }}
                    >
                      {lang === "English" ? "(Optional)" : lang === "Italian" ? "(Opzionale)" : "(Opcional)"}
                    </span>
                  </label>
                  <input
                    placeholder={
                      lang === "English" ? "Please Enter ID" : lang === "Italian" ? "Si prega di inserire l'ID" : "Por favor ingrese su ID"
                    }
                    value={beUserId}
                    onChange={(e) => setBeUserId(e.target.value)}
                  />
                </div>
                {/* Localization Toogle button is here */}

                <div className="profile__btn">
                  <button
                    disabled={updateLoader ? true : false}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => setUpUser()}
                  >
                    <p> {lang === "English" ? "Set Up" : lang === "Italian" ? "Configurazione" : "Configuración"}</p>
                    {/* {updateLoader && <CircularProgress />} */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <NavBar /> */}
    </Layout>
  );
};

export default Profile;
