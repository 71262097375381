// import React, { useRef, useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
// import {convertStringToNumber} from 'convert-string-to-number'

// import Layout from "../components/Layout/Layout";
// import NavBar from '../components/NavBar/NavBar';

// import { updateBoostLevel } from "../api/updateBoostLevel";

// import moveIcon from '../assets/moveIcon.png';
// import tickIcon from '../assets/tickIcon.png';
// import notificationIcon from '../assets/notificationIcon.png';
// import multiTapIcon from '../assets/multiTapIcon.png';
// import energyIcon from '../assets/energy.png';
// import astronautIcon from '../assets/astronaut.png';
// import dotIcon from '../assets/dot.png';
// import closeIcon from '../assets/closeIcon.png';

// import { formatNumber } from "../utils/formatNumber";

// const Earn = () => {
//     const token = sessionStorage.getItem('token');
//     const points = sessionStorage.getItem('points');
//     const level = sessionStorage.getItem('level');
//     const [multiTapLevel, setMultiTapLevel] = useState(0);
//     const [dailyAttemptsLevel, setDailyAttemptsLevel] = useState(0);
//     const navigate = useNavigate();
//     const dropdownRef = useRef(null);
//     const [notification, setNotification] = useState('');
//     const [notificationVisibile, setNotificationVisibile] = useState(false);
//     const [dropdownVisible, setDropdownVisible] = useState(false);
//     const [boostType, setBoostType] = useState('');
//     const [score, setScore] = useState(0);

//     const requiredScoreForMultiTap = 2000 * (2 ** multiTapLevel);
//     const requiredScoreForDailyAttempts = 2000 * (2 ** dailyAttemptsLevel);
//     const formattedScoreForMultiTap = formatNumber(requiredScoreForMultiTap);
//     const formattedScoreForDailyAttempts = formatNumber(requiredScoreForDailyAttempts);
//     useEffect(()=>{
//         let ml = sessionStorage.getItem('multiTapLevel');
//         ml = convertStringToNumber(ml);
//         setMultiTapLevel(ml);
//         let dl = sessionStorage.getItem('dailyAttemptsLevel');
//         dl = convertStringToNumber(dl);
//         setDailyAttemptsLevel(dl);
//         const pnts = sessionStorage.getItem('points');
//         setScore(pnts); 
//     }, [])
//     const handleClickDouble = () => {
//         navigate('/flip');
//     }

//     const handleClickMultiTap = () => {
//         setBoostType('multiTap');
//         setDropdownVisible(!dropdownVisible);
//     }

//     const handleClickDailyAttempts = () => {
//         setBoostType('dailyAttempts');
//         setDropdownVisible(!dropdownVisible);
//     }

//     const handleClickMultiTapGet = async () => {
//         const result = await updateBoostLevel(token, 'multiTap');
//         if (result == true) {
//             setScore(score - requiredScoreForMultiTap);
//             setMultiTapLevel(multiTapLevel + 1);
//             setDropdownVisible(!dropdownVisible);
//             setNotification('Multitap successfully added');
//             setNotificationVisibile(true);
//             setTimeout(() => {
//                 setNotificationVisibile(false);
//                 setTimeout(() => setNotification(''), 3000);
//             }, 2000);
//         }
//     }

//     const handleClickDailyAttemptsGet = async () => {
//         const result = await updateBoostLevel(token, 'dailyAttempts');
//         if (result == true) {
//             setScore(score - requiredScoreForDailyAttempts);
//             setDailyAttemptsLevel(dailyAttemptsLevel + 1);
//             setDropdownVisible(!dropdownVisible);
//             setNotification('Attempts successfully added');
//             setNotificationVisibile(true);
//             setTimeout(() => {
//                 setNotificationVisibile(false);
//                 setTimeout(() => setNotification(''), 3000);
//             }, 2000);
//         }
//     }

//     useEffect(() => {
//         const handleOutsideClick = (event) => {
//             if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.closest('.boostButton')) {
//                 setDropdownVisible(false);
//             }
//         };

//         if (dropdownVisible) {
//             document.addEventListener('mousedown', handleOutsideClick);
//         } else {
//             document.removeEventListener('mousedown', handleOutsideClick);
//         }

//         return () => {
//             document.removeEventListener('mousedown', handleOutsideClick);
//         };
//     }, [dropdownVisible, setDropdownVisible]);

//     const closeDropdown = () => {
//         setDropdownVisible(false);
//     }

//     return (
//         <Layout>
//             <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', paddingBlock: '1.5rem', position: 'relative' }}>
//                 <div style={{ marginBlock: '0.3125rem', color: 'white', fontSize: '3rem', fontWeight: '700', fontFamily: 'Montserrat' }}>{formatNumber(score)}</div>
//                 <button style={{ backgroundColor: 'rgba(140, 140, 140, 0.1)', color: 'white', border: 'none', marginBlock: '0.3125rem', padding: '0.34375rem 2rem', fontFamily: 'Montserrat', fontSize: '0.75rem', fontWeight: '500', borderRadius: '6.25rem', cursor: 'pointer' }} >Level {level}</button>
//                 <div style={{ width: '100%', paddingInline: '1rem' }}>
//                     <div style={{ marginTop: '2.1875rem' }}>
//                         <span style={{ color: 'white', fontFamily: 'Montserrat', fontWeight: '600', fontSize: '1.5rem' }}>Boosters</span>
//                     </div>
//                     <div style={{ width: '100%', background: 'rgba(140,140,140,0.1)', backdropFilter: 'blur(0.5rem)', WebkitBackdropFilter: 'blur(0.5rem)', padding: '1.5625rem', marginTop: '1.25rem', borderRadius: '1.25rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', gap: '1rem' }}>
//                         <button style={{ border: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: score < requiredScoreForMultiTap ? 'auto' : 'pointer', background: 'transparent', width: '100%', opacity: score < requiredScoreForMultiTap ? '0.1' : '1' }} onClick={score < requiredScoreForMultiTap ? null : handleClickMultiTap} className="boostButton">
//                             <div style={{ display: 'flex', alignItems: 'center' }}>
//                                 <img src={multiTapIcon} alt="MultiTap Icon" style={{ width: '3.75rem', height: '3.75rem', background: 'rgba(140,140,140,0.1)', borderRadius: '50%', padding: '0.5rem' }} />
//                                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginLeft: '1rem' }}>
//                                     <span style={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '1.125rem', color: 'white' }}>Multitap</span>
//                                     <div style={{ display: 'flex', alignItems: 'center', gap: '0.6rem' }}>
//                                         <span style={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '1.125rem', color: 'rgba(255,255,255,0.8)' }}>{formattedScoreForMultiTap}</span>
//                                         <img src={dotIcon} alt="Dot Icon" style={{ width: '0.3125rem', height: '0.3125rem' }} />
//                                         <span style={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '1.125rem', color: 'rgba(255,255,255,0.6)' }}>{multiTapLevel} lvl</span>
//                                     </div>
//                                 </div>
//                             </div>
//                             {score < requiredScoreForMultiTap ? (
//                                 <img src={tickIcon} alt="Tick Icon" style={{ width: '1.25rem', height: '1.25rem' }} />
//                             ) : (
//                                 <img src={moveIcon} alt="Move Icon" style={{ width: '1.25rem', height: '1.25rem' }} />
//                             )}
//                         </button>
//                         <button style={{ border: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: score < requiredScoreForDailyAttempts ? 'auto' : 'pointer', background: 'transparent', width: '100%', opacity: score < requiredScoreForDailyAttempts ? '0.1' : '1' }} onClick={score < requiredScoreForDailyAttempts ? null : handleClickDailyAttempts} className="boostButton">
//                             <div style={{ display: 'flex', alignItems: 'center' }}>
//                                 <img src={energyIcon} alt="Energy Icon" style={{ width: '3.75rem', height: '3.75rem', background: 'rgba(140,140,140,0.1)', borderRadius: '50%', padding: '0.5rem' }} />
//                                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginLeft: '1rem' }}>
//                                     <span style={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '1.125rem', color: 'white' }}>Daily Attempts</span>
//                                     <div style={{ display: 'flex', alignItems: 'center', gap: '0.6rem' }}>
//                                         <span style={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '1.125rem', color: 'rgba(255,255,255,0.8)' }}>{formattedScoreForDailyAttempts}</span>
//                                         <img src={dotIcon} alt="Dot Icon" style={{ width: '0.3125rem', height: '0.3125rem' }} />
//                                         <span style={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '1.125rem', color: 'rgba(255,255,255,0.6)' }}>{dailyAttemptsLevel} lvl</span>
//                                     </div>
//                                 </div>
//                             </div>
//                             {score < requiredScoreForDailyAttempts ? (
//                                 <img src={tickIcon} alt="Tick Icon" style={{ width: '1.25rem', height: '1.25rem' }} />
//                             ) : (
//                                 <img src={moveIcon} alt="Move Icon" style={{ width: '1.25rem', height: '1.25rem' }} />
//                             )}
//                         </button>
//                     </div>
//                     <div style={{ width: '100%', background: 'rgba(140,140,140,0.1)', backdropFilter: 'blur(0.5rem)', WebkitBackdropFilter: 'blur(0.5rem)', padding: '1.5625rem', marginTop: '2.1875rem', borderRadius: '1.25rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}>
//                         <img src={astronautIcon} alt="Astronaut Icon" style={{ width: '6.25rem', height: '6.25rem' }} />
//                         <span style={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '1.5rem', color: 'white', marginTop: '1.5rem' }}>Moon Coin Game x2</span>
//                         <span style={{ fontFamily: 'Montserrat', fontWeight: '400', fontSize: '1rem', color: '#F1F1F1', marginTop: '1.5625rem', textAlign: 'center' }}>Double your Coins and Level Up even Faster!</span>
//                         <button style={{ width: '100%', background: 'linear-gradient(45deg, #5DA8FF, #0064D7)', paddingBlock: '1.125rem', border: 'none', marginTop: '2.1875rem', borderRadius: '18.75rem', color: 'white', fontFamily: 'Montserrat', fontSize: '1.125rem', cursor: 'pointer', fontWeight: '600' }} onClick={handleClickDouble}>
//                             Double
//                         </button>
//                     </div>
//                 </div>
//                 {notification &&
//                     <div style={{ position: 'absolute', bottom: '1rem', zIndex: '2', width: '100%' }}>
//                         <div style={{ marginInline: '1.875rem', background: 'rgba(140, 140, 140, 0.1)', backdropFilter: 'blur(1rem)', WebkitBackdropFilter: 'blur(0.5rem)', borderRadius: '18.75rem', paddingBlock: '1.25rem', display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: notificationVisibile ? 1 : 0, transition: 'opacity 0.5s ease-out', pointerEvents: notificationVisibile ? 'auto' : 'none' }}>
//                             <img src={notificationIcon} alt='Notification Icon' style={{ width: '1.5625rem', height: '1.5625rem' }} />
//                             <span style={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '1.5rem', color: 'white', marginLeft: '0.375rem' }}>{notification}</span>
//                         </div>
//                     </div>
//                 }
//             </div>
//             {dropdownVisible && (
//                 <div ref={dropdownRef} style={{ width: '100%', borderRadius: '1.25rem 1.25rem 0 0', background: 'rgba(140,140,140,0.1)', backdropFilter: 'blur(3rem)', WebkitBackdropFilter: 'blur(3rem)', position: 'fixed', bottom: '0rem', display: 'flex', flexDirection: 'column', padding: '1rem', alignItems: 'center' }}>
//                     <button style={{ position: 'absolute', top: '1rem', right: '1rem', float: 'right', background: 'none', border: 'none', cursor: 'pointer' }} onClick={closeDropdown} >
//                         <img src={closeIcon} alt="Close Icon" style={{ width: '1.875rem', height: '1.875rem' }} />
//                     </button>
//                     <img src={boostType == 'multiTap' ? multiTapIcon : energyIcon} alt={boostType == 'multiTap' ? "Multi Tap Icon" : "Energy Icon"} style={{ width: '6.25rem', height: '6.25rem' }} />
//                     <span style={{ marginTop: '1.5625rem', fontFamily: 'Montserrat', fontWeight: '600', fontSize: '1.75rem', color: 'white' }}>{boostType == 'multiTap' ? 'Multi Tap' : 'Daily Attempts'}</span>
//                     <span style={{ marginTop: '1.5625rem', fontFamily: 'Montserrat', fontWeight: '400', fontSize: '1.125rem', color: '#F1F1F1', textAlign: 'center' }}>{boostType == 'multiTap' ? (
//                         <>
//                             Increase Amount of Moon Coin
//                             <br />
//                             You can Earn per One Tap
//                         </>
//                     ) : (
//                         <>
//                             Increase your Daily Attempts limit,
//                             <br />
//                             so you can Mine More per Session.
//                         </>
//                     )}</span>
//                     <span style={{ fontFamily: 'Montserrat', fontWeight: '400', fontSize: '1.125rem', color: '#F1F1F1', opacity: '0.6', marginTop: '1.5625rem' }}>{boostType == 'multiTap' ? '+1 per Tap for Each level' : '+1 attempt for Each level'}</span>
//                     <span style={{ marginTop: '1.25rem', fontFamily: 'Montserrat', fontWeight: '700', fontSize: '1.75rem', color: 'white' }}>{boostType == 'multiTap' ? formattedScoreForMultiTap : formattedScoreForDailyAttempts}</span>
//                     <button style={{ marginTop: '1.875rem', borderRadius: '18.75rem', background: 'linear-gradient(45deg, #5DA8FF, #0064D7)', width: '100%', paddingBlock: '1.125rem', color: 'white', fontFamily: 'Montserrat', fontWeight: '600', fontSize: '1.125rem', cursor: 'pointer' }} onClick={boostType == 'multiTap' ? handleClickMultiTapGet : handleClickDailyAttemptsGet}>Get</button>
//                 </div>
//             )
//             }
//             <div style={{height: '65px'}}></div>
//             <NavBar />
//         </ Layout >
//     );
// };

// export default Earn;