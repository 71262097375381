import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useNavigate } from "react-router-dom";

import "./NavBar.css";

const NavBar = forwardRef((prop, ref) => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(null);
  const [path, setPath] = useState();

  const handleClick = (buttonName, path) => {
    setActiveButton(buttonName);
    navigate(path);
  };
  useEffect(() => {
    const loc = window.location.pathname;
    setPath(loc);

    console.log(loc);
  });

  // const flip = () => { navigate('/flip'); }
  // const friends = () => { navigate('/invite'); }
  // const earn = () => { navigate('/earn'); }
  // const boosts = () => { navigate('/boosts'); }
  // const handleClickPreSale = () => {
  //     navigate('/presale');
  // }

  const [lang, setLang] = useState("");

  // Function to update the child's state
  const updateNavLanguagePref = () => {
    const savedLanguage = sessionStorage.getItem("Language");
    setLang(savedLanguage);
  };

  // Exposing the function to the parent through the ref
  useImperativeHandle(ref, () => ({
    updateNavLanguagePref,
  }));

  useEffect(() => {
    const savedLanguage = sessionStorage.getItem("Language");
    setLang(savedLanguage);
  }, []);

  return (
    <div className="button-row">
      <button
        className={`button-item ${path === "/invite" ? "active" : ""}`}
        onClick={() => handleClick("invite", "/invite")}
        style={{ display: "flex", justifyContent: "space-around", gap: "5px" }}
      >
        <img src="/Images/Navbar/invite.svg" alt="Flip x2" className="icon" />
        <span>
          {/* {lang === "English"
            ? "Invite"
            : lang === "Italian"
            ? "Invita"
            : "Invitar"} */}

          {lang === "Spanish"
            ? "Invitar"
            : lang === "Italian"
            ? "Invita"
            : "Invite"}
        </span>
      </button>
      <button
        className={`button-item ${path === "/earn" ? "active" : ""}`}
        onClick={() => handleClick("tasks", "/earn")}
      >
        <img src="/Images/Navbar/task.svg" alt="Friends" className="icon" />
        <span>
          {/* {lang === "English"
            ? "Tasks"
            : lang === "Italian"
            ? "Compiti"
            : "Tareas"} */}

          {lang === "Spanish"
            ? "Tareas"
            : lang === "Italian"
            ? "Compiti"
            : "Tasks"}
        </span>
      </button>
      <button
        className={`button-item ${
          path === "/joinclan"
            ? "active"
            : path?.includes("/community")
            ? "active"
            : ""
        }`}
        onClick={() => handleClick("rankings", "/joinclan")}
      >
        <img
          src="/Images/Navbar/rank.svg"
          alt="Earn"
          className="icon"
          style={{ width: "50px", height: "50px" }}
        />
        <span style={{ fontSize: "12px", fontWeight: "700" }}>
          {/* {lang === "English"
            ? "Home"
            : lang === "Italian"
            ? "Iniziale"
            : "Hogar"} */}

          {lang === "Spanish"
            ? "Hogar"
            : lang === "Italian"
            ? "Iniziale"
            : "Home"}
        </span>
      </button>
      <button
        className={`button-item ${
          path === "/redeemfirst" || path === "/Redeem" ? "active" : ""
        }`}
        onClick={() => handleClick("redeem", "/redeemfirst")}
      >
        <img src="/Images/Navbar/redeem.svg" alt="Boosts" className="icon" />
        <span>
          {/* {lang === "English"
            ? "Redeem"
            : lang === "Italian"
            ? "Riscatta"
            : "Canjear"} */}

          {lang === "Spanish"
            ? "Canjear"
            : lang === "Italian"
            ? "Riscatta"
            : "Redeem"}
        </span>
      </button>
      <button
        className={`button-item ${path === "/editProfile" ? "active" : ""}`}
        onClick={() => handleClick("profile", "/editProfile")}
      >
        <img src="/Images/Navbar/profile.svg" alt="presale" className="icon" />
        <span>
          {/* {lang === "English"
            ? "Profile"
            : lang === "Italian"
            ? "Profilo"
            : "Perfil"} */}

          {lang === "Spanish"
            ? "Perfil"
            : lang === "Italian"
            ? "Profilo"
            : "Profile"}
        </span>
      </button>
    </div>
  );
});

export default NavBar;
