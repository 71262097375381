export const updateProfile = async (
  token,
  name,
  beUserId,
  imageSrc,
  country
) => {
  try {
    const body = {};
    body.name = name;
    body.beUserId = beUserId;
    body.profilePic = imageSrc;
    body.country = country;
    const response = await fetch("https://api.orbit-z.com/profile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error("Failed to update user data");
    } else return true;
  } catch (error) {
    console.error("Error:", error);
  }
};
