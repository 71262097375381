// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/FontFamily/Montserrat-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/FontFamily/Montserrat-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  /* font-family: 'Montserrat', sans-serif; */
  background-color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: "Montserrat";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype") ;
}
@font-face {
  font-family: "MontserratBold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype") ;
}
p , h1 ,h2 ,h3 ,h4 ,h5 , h6{
  margin: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,2CAA2C;EAC3C,yBAAyB;EACzB,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;;;GAGG;;AAEH;EACE,yBAAyB;EACzB,gEAAwE;AAC1E;AACA;EACE,6BAA6B;EAC7B,gEAAsE;AACxE;AACA;EACE,sBAAsB;AACxB","sourcesContent":["body {\n  margin: 0;\n  /* font-family: 'Montserrat', sans-serif; */\n  background-color: #000000;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n/* code {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n} */\n\n@font-face {\n  font-family: \"Montserrat\";\n  src: url('/public/FontFamily/Montserrat-Medium.ttf') format(\"truetype\") ;\n}\n@font-face {\n  font-family: \"MontserratBold\";\n  src: url('/public/FontFamily/Montserrat-Bold.ttf') format(\"truetype\") ;\n}\np , h1 ,h2 ,h3 ,h4 ,h5 , h6{\n  margin: 0px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
