// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stars-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -2;
}

.star {
  position: absolute;
  background-color: white;
  border-radius: 50%;
  opacity: 0;
  animation: twinkling 5s infinite ease-in-out, move 10s infinite linear, fade-in-out infinite;
}


@keyframes twinkling {

  0%,
  100% {
    opacity: 0.3;
  }

  50% {
    opacity: 0.7;
    transform: scale(var(--random-scale, 1));
  }
}

@keyframes move {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(var(--random-x, 0px), var(--random-y, 0px));
  }

  100% {
    transform: translate(0, 0);
  }
}


@keyframes fade-in-out {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.star {
  --random-x: calc((rand() - 0.5) * 10px);
  /* Subtle x-axis movement */
  --random-y: calc((rand() - 0.5) * 10px);
  /* Subtle y-axis movement */
  --random-scale: calc((rand() * 0.3) + 0.85);
  /* Subtle size scale */
}`, "",{"version":3,"sources":["webpack://./src/components/Stars/Stars.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,kBAAkB;EAClB,UAAU;EACV,4FAA4F;AAC9F;;;AAGA;;EAEE;;IAEE,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,wCAAwC;EAC1C;AACF;;AAEA;EACE;IACE,0BAA0B;EAC5B;;EAEA;IACE,gEAAgE;EAClE;;EAEA;IACE,0BAA0B;EAC5B;AACF;;;AAGA;;EAEE;;IAEE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,uCAAuC;EACvC,2BAA2B;EAC3B,uCAAuC;EACvC,2BAA2B;EAC3B,2CAA2C;EAC3C,sBAAsB;AACxB","sourcesContent":[".stars-container {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  z-index: -2;\n}\n\n.star {\n  position: absolute;\n  background-color: white;\n  border-radius: 50%;\n  opacity: 0;\n  animation: twinkling 5s infinite ease-in-out, move 10s infinite linear, fade-in-out infinite;\n}\n\n\n@keyframes twinkling {\n\n  0%,\n  100% {\n    opacity: 0.3;\n  }\n\n  50% {\n    opacity: 0.7;\n    transform: scale(var(--random-scale, 1));\n  }\n}\n\n@keyframes move {\n  0% {\n    transform: translate(0, 0);\n  }\n\n  50% {\n    transform: translate(var(--random-x, 0px), var(--random-y, 0px));\n  }\n\n  100% {\n    transform: translate(0, 0);\n  }\n}\n\n\n@keyframes fade-in-out {\n\n  0%,\n  100% {\n    opacity: 0;\n  }\n\n  50% {\n    opacity: 1;\n  }\n}\n\n.star {\n  --random-x: calc((rand() - 0.5) * 10px);\n  /* Subtle x-axis movement */\n  --random-y: calc((rand() - 0.5) * 10px);\n  /* Subtle y-axis movement */\n  --random-scale: calc((rand() * 0.3) + 0.85);\n  /* Subtle size scale */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
