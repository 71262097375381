export const updateExchangeStatus = async (token, exchangeId) => {
    try {
        const body = { exchangeId };
        console.log(token, body);
        const response = await fetch(`https://api.orbit-z.com/exchangeStatus`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        });
        if (response.ok) {
            return true;
        } else {
            console.log('Failed to update exchange status');
            return false;
        }
    } catch (error) {
        console.error('Error in updateExchangeStatus:', error);
        return false;
    }
}