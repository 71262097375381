export const getExchanges = async (token) => {
    try {
        const response = await fetch(`https://api.orbit-z.com/exchange`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        if (response.ok) {
            const data = await response.json();
            const exchanges = data.exchanges;
            return exchanges;
        } else {
            throw new Error('Failed to fetch exchanges');
        }
    } catch (error) {
        console.error('Error in getExchanges:', error);
    }
}